
const photosDrush = [
    {
      src: "/img/DocDrush/1.jpeg",
      width: 4,
      height: 3
    }, 
    {
      src: "/img/DocDrush/2.jpeg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocDrush/3.jpeg",
      width: 4,
      height: 3
    }, 
    {
      src: "/img/DocDrush/4.jpeg",
      width: 3,
      height: 4
    }, 
    {
      src: "/img/DocDrush/5.jpeg",
      width: 3,
      height: 4
    }, 
     {
      src: "/img/DocDrush/6.jpeg",
      width: 4,
      height: 3
    },  
    {
      src: "/img/DocDrush/7.jpeg",
      width: 4,
      height: 3
    }, 
     {
      src: "/img/DocDrush/8.jpeg",
      width: 4,
      height: 3
    }, {
      src: "/img/DocDrush/9.jpeg",
      width: 3,
      height: 4
    },  
    {
      src: "/img/DocDrush/10.jpeg",
      width: 4,
      height: 3
    },  {
      src: "/img/DocDrush/11.jpeg",
      width: 4,
      height: 3
    },  {
      src: "/img/DocDrush/12.jpeg",
      width: 4,
      height: 3
    }, 
  
];
export default photosDrush;