import React from "react";
import { Container, Row, Col } from "react-grid-system";
import "./Hirurg.scss";
import { Helmet } from "react-helmet";
const Hirurg = () =>{
    return(
        <>
		<Helmet>
			<title>Хирургические услуги</title>
		</Helmet>
        <div className="hir1">
        <h1>Наши услуги</h1>
        <span className="animateСharcterHir">Хирургические услуги</span>
     
        </div>

            <Container>
		
                <Row>
                    <Col lg={4}>
   <div className="hir2">
            <img src="/img/Uslug/hirurg.JPG"  alt="imag"/>
			</div>
                    </Col>
                    <Col lg={8}>
						<div className="hir22">
		<p>	
			Экстракция ( удаление зуба ) возможна в плановом или в экстренном порядке. 
			В зависимости от нюансов конкретного случая, манипуляция может занимать от
			нескольких минут до часа.
				
	Соответственно, цена операции зависит от сложности ситуации,
	глубины посадки зуба в костной ткани, наличия/отсутствия воспалительных 
	процессов, степени искривления корня и иных факторов.

	Услуги Центра Современной Стоматологии  — это Ваша возможность безболезненно 
	и атравматично удалить зуб, сохранив при этом максимальный объем костной ткани 
	для дальнейшей имплантации, а так же мы располагаем всем арсеналом инструментов 
	для проведения удаления и обезболивания любой сложности.
</p>				  
</div>
				    </Col>
					</Row>
					
            </Container>
		
						<Container>
                    <table className="tbgig">
	<tbody>
	
		<tr>	
		
			<td colSpan={2} align="center">	<div className="td2" >Прейскурант цен ООО «Центр Современной Стоматологии» <br /> на хирургические услуги </div></td>
		
		</tr>
        <tr>	
		
        <td colSpan={2} align="center">	<div className="td2" >Хирургия</div></td>
    
    </tr>
		
		<tr>
			<td><div >Удаление зуба (простое)</div></td>
			<td><div className="td2" >3 000</div></td>
		</tr>
		<tr>
			<td><div >​​​​​​​Удаление зуба (сложное)</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
		<tr>
			<td><div >​​​​​​Удаление 8-го зуба (мудрости) горизонтально расположенного (дистопированного)</div></td>
			<td><div className="td2" >10 000</div></td>
		</tr>	
		<tr>
			<td><div >​​​​​​​​​​​​Удаление ретенционной кисты</div></td>
			<td><div className="td2" >15 000</div></td>
		</tr>
    	{/* <tr>
			<td><div >Обезболивание: аппликационная анестезия</div></td>
			<td><div className="td2" >400</div></td>
		</tr>
        <tr>
			<td><div >Обезболивание: дополнительная анестезия</div></td>
			<td><div className="td2" >400</div></td>
		</tr>
        <tr>
			<td><div >Обезболивание: интралигаментарная анестезия</div></td>
			<td><div className="td2" >600</div></td>
		</tr>
        <tr>
			<td><div >Обезболивание: инфильтрационная анестезия</div></td>
			<td><div className="td2" >600</div></td>
		</tr>

        <tr>	
		
        <td colSpan={2} align="center">	<div className="td2" >Имплантация</div></td>
    </tr>



        <tr>
			<td><div >Установка МЮ "Snucone"</div></td>
			<td><div className="td2" >10 000</div></td>
		</tr>
        <tr>
			<td><div >Удаление интегрированного  импланта</div></td>
			<td><div className="td2" >10 000</div></td>
		</tr>
        <tr>
			<td><div >Использование абатмента Multi-unit Xeal Conical</div></td>
			<td><div className="td2" >21 000</div></td>
		</tr>
        <tr>
			<td><div >Использование заживляющего колпачка Multi-unit</div></td>
			<td><div className="td2" >2 000</div></td>
		</tr>
        <tr>
			<td><div >Заполнение костного дефекта ксеногенным костным материалом 0,5 гр</div></td>
			<td><div className="td2" >15 000</div></td>
		</tr>
        <tr>
			<td><div >Заполнение костного дефекта ксеногенным костным материалом 1 гр</div></td>
			<td><div className="td2" >23 000</div></td>
		</tr>
        <tr>
			<td><div >Костная пластика с использованием  i-Gen </div></td>
			<td><div className="td2" >32 300</div></td>
		</tr>
        <tr>
			<td><div >Изготовление индивидуального формирователя десны</div></td>
			<td><div className="td2" >7 000</div></td>
		</tr>

        <tr>
			<td><div >Имплантация Nobel Biocare по концепции ALL-ON-4</div></td>
			<td><div className="td2" >350 000</div></td>
		</tr>
        <tr>
			<td><div >Имплантация и временное протезирование Nobel Biocare по концепции ALL-ON-4</div></td>
			<td><div className="td2" >500 000</div></td>
		</tr>
        <tr>
			<td><div >Имплантация и временное протезирование Nobel Biocare по концепции ALL-ON-6</div></td>
			<td><div className="td2" >550 000</div></td>
		</tr>
        <tr>
			<td><div >Имплантация и постоянное протезирование Nobel Biocare по концепции Trefoil</div></td>
			<td><div className="td2" >500 000</div></td>
		</tr>
        <tr>
			<td><div >Операция дентальной имплантации постановка одного импланта Any Ridge</div></td>
			<td><div className="td2" >38 000</div></td>
		</tr>
        <tr>
			<td><div >Операция дентальной имплантации постановка одного импланта INNO</div></td>
			<td><div className="td2" >35 000</div></td>
		</tr>
        <tr>
			<td><div >Операция дентальной имплантации постановка одного импланта Nobel Biocare</div></td>
			<td><div className="td2" >53 000</div></td>
		</tr>
        <tr>
			<td><div >Операция дентальной имплантации постановка одного импланта Snucone</div></td>
			<td><div className="td2" >28 500</div></td>
		</tr>
        <tr>
			<td><div >Применение «Ролл» техники в области имплантата или формирователя десны</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
        <tr>
			<td><div >Установка десневого формирователя INNO</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
        <tr>
			<td><div >Установка десневого формирователя Nobel</div></td>
			<td><div className="td2" >5 500</div></td>
		</tr>
        <tr>
			<td><div >Установка десневого формирователя Straumann</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
        <tr>
			<td><div >Установка десневого формирователя для Any Ridge</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
        <tr>
			<td><div >Установка десневого формирователя для Snucone</div></td>
			<td><div className="td2" >4 000</div></td>
		</tr>
        <tr>
			<td><div >Увеличение зоны прикрепленной десны (стрип- пластика)</div></td>
			<td><div className="td2" >16 000</div></td>
		</tr>
        <tr>
			<td><div >Увеличение объема десны в области имплантанта</div></td>
			<td><div className="td2" >6 500</div></td>
		</tr>


        <tr>	
		
        <td colSpan={2} align="center">	<div className="td2" >Имплантация и протезирование</div></td>
    </tr>




        <tr>
			<td><div >Изготовление и фиксация временного протеза с опорой на 6 имплантатов с уровня мультиюнита из РММА</div></td>
			<td><div className="td2" >77 000</div></td>
		</tr>
        <tr>
			<td><div >Ремонт постоянного протеза с опорой на 6 имплантатов Nobel Biocare</div></td>
			<td><div className="td2" >20 000</div></td>
		</tr>
        <tr>
			<td><div >Установка несъемного временного мостовидного протеза </div></td>
			<td><div className="td2" >100 000</div></td>
		</tr>
        <tr>
			<td><div >Установка несъемного временного мостовидного протеза с опрой на 6 имплантатах Snucone</div></td>
			<td><div className="td2" >150 000</div></td>
		</tr>
        <tr>
			<td><div >Установка несъемного временного протеза на одну челюсть с опорой на  4 имплантата Nobel Biocare</div></td>
			<td><div className="td2" >250 000</div></td>
		</tr>
        <tr>
			<td><div >Установка постоянного протеза с опорой на 4 имплантата Nobel Biocare</div></td>
			<td><div className="td2" >220 000</div></td>
		</tr>
        <tr>
			<td><div >Установка постоянного протеза с опорой на 6 имплантатах Nobel Biocare</div></td>
			<td><div className="td2" >170 000</div></td>
		</tr>
        <tr>
			<td><div >Установка постоянного протеза с опорой на 6 имплантатах Snucon</div></td>
			<td><div className="td2" >120 000</div></td>
		</tr>




        <tr>	
		
        <td colSpan={2} align="center">	<div className="td2" >Костнопластические операции</div></td>
    </tr>



        <tr>
			<td><div >Немедленная реконструкция альвеолярного гребня (IDR)</div></td>
			<td><div className="td2" >50 000</div></td>
		</tr>
        <tr>
			<td><div >Забор кости в области угла нижней челюсти с использованием костного скребка</div></td>
			<td><div className="td2" >15 000</div></td>
		</tr>
        <tr>
			<td><div >Использование костного материала 0,25 гр.</div></td>
			<td><div className="td2" >13 000</div></td>
		</tr>
        <tr>
			<td><div >Реконструкция альвеолярного гребня по вертикали  в области двух имплантов</div></td>
			<td><div className="td2" >110 000</div></td>
		</tr>
        <tr>
			<td><div >Реконструкция альвеолярного гребня по вертикали  в области одного импланта</div></td>
			<td><div className="td2" >83 000</div></td>
		</tr>
        <tr>
			<td><div >Реконструкция альвеолярного гребня по вертикали  в области трех имплантов</div></td>
			<td><div className="td2" >140 000</div></td>
		</tr>

        <tr>
			<td><div >Реконструкция альвеолярного гребня по ширине (Sausage техника) в области двух - трех  имплантов</div></td>
			<td><div className="td2" >117 000</div></td>
		</tr>

        <tr>
			<td><div >Реконструкция альвеолярного гребня по ширине (Sausage техника) в области одного импланта</div></td>
			<td><div className="td2" >87 000</div></td>
		</tr>

        <tr>
			<td><div ></div></td>
			<td><div className="td2" ></div></td>
		</tr>

        <tr>
			<td><div >Сохранение новобразованного костного объема (методика Baby Bone)</div></td>
			<td><div className="td2" >58 000</div></td>
		</tr>

        <tr>
			<td><div >Повторная операция имплантации с применением импланта Snucone</div></td>
			<td><div className="td2" >1 300</div></td>
		</tr>

        <tr>
			<td><div >Использование костного материала 0,5 гр.</div></td>
			<td><div className="td2" >15 000</div></td>
		</tr>

        <tr>
			<td><div ></div></td>
			<td><div className="td2" ></div></td>
		</tr>

        <tr>
			<td><div >Использование костного материала 1 гр.</div></td>
			<td><div className="td2" >23 000</div></td>
		</tr>

        <tr>
			<td><div >Операция по проведению закрытого синус-лифтинга (мягкий) в области одного импланта</div></td>
			<td><div className="td2" >30 000</div></td>
		</tr>

        <tr>
			<td><div >Операция по проведению открытого синус-лифтинга в области 2 и более имплантов</div></td>
			<td><div className="td2" >81 000</div></td>
		</tr>

        <tr>
			<td><div >Операция по проведению открытого синус-лифтинга в области одного импланта</div></td>
			<td><div className="td2" >54 000</div></td>
		</tr>



        <tr>	
		
        <td colSpan={2} align="center">	<div className="td2" >Хирургические манипуляции</div></td>
    </tr>



        <tr>
			<td><div >Прицельная рентгенография</div></td>
			<td><div className="td2" >400</div></td>
		</tr>

        <tr>
			<td><div >Гистологический анализ </div></td>
			<td><div className="td2" >2 990</div></td>
		</tr>

        <tr>
			<td><div >Удаление молочного зуба простое</div></td>
			<td><div className="td2" >1 560</div></td>
		</tr>

        <tr>
			<td><div >Удаление молочного зуба сложное</div></td>
			<td><div className="td2" >2 500</div></td>
		</tr>

        <tr>
			<td><div >Гермэктомия</div></td>
			<td><div className="td2" >11 050</div></td>
		</tr>

        <tr>
			<td><div >Использование PRF мембраны</div></td>
			<td><div className="td2" >3 650</div></td>
		</tr>

        <tr>
			<td><div >Использование коллагеновой губки</div></td>
			<td><div className="td2" >260</div></td>
		</tr>

        <tr>
			<td><div >Презервация лунки PRF пробкой</div></td>
			<td><div className="td2" >3 400</div></td>
		</tr>

        <tr>
			<td><div >Презервация лунки остеобластическим материалом + PRF -мембраной</div></td>
			<td><div className="td2" >25 000</div></td>
		</tr>

        <tr>
			<td><div >Презервация лунки остеобластическим материалом + коллагеновой резорбируемой мембраной</div></td>
			<td><div className="td2" >43 000</div></td>
		</tr>

        <tr>
			<td><div ></div></td>
			<td><div className="td2" ></div></td>
		</tr>

        <tr>
			<td><div >Трансплантация удаленного зуба</div></td>
			<td><div className="td2" >13 000</div></td>
		</tr>

        <tr>
			<td><div >Удаление 8-го зуба  (мудрости) горизонтально расположенного (дистопированного) </div></td>
			<td><div className="td2" >10 000</div></td>
		</tr>

        <tr>
			<td><div >Удаление 8-го зуба (зуба мудрости) в атипичном положении на верхней челюсти</div></td>
			<td><div className="td2" >10 000</div></td>
		</tr>

        <tr>
			<td><div ></div></td>
			<td><div className="td2" ></div></td>
		</tr>

        <tr>
			<td><div >Удаление 8-го зуба (зуба мудрости) на верхней челюсти</div></td>
			<td><div className="td2" >7 500</div></td>
		</tr>

        <tr>
			<td><div >Удаление 8-го зуба (зуба мудрости) с аномалией корней</div></td>
			<td><div className="td2" >11 000</div></td>
		</tr>

        <tr>
			<td><div >Удаление 8-го зуба (зуба мудрости) с выпиливанием кортикальной пластины</div></td>
			<td><div className="td2" >14 000</div></td>
		</tr>

        <tr>
			<td><div >Удаление зуба (простое)</div></td>
			<td><div className="td2" >3 000</div></td>
		</tr>

        <tr>
			<td><div >Удаление непрорезавшегося третьего моляра (зуба мудрости) при вертикальном залегании</div></td>
			<td><div className="td2" >7 000</div></td>
		</tr>

        <tr>
			<td><div >Удаление непрорезавшегося третьего моляра (зуба мудрости) при дистально-косом залегании</div></td>
			<td><div className="td2" >12 000</div></td>
		</tr>

        <tr>
			<td><div >Удаление непрорезавшегося третьего моляра (зуба мудрости) при медиально-косом залегании</div></td>
			<td><div className="td2" >12 000</div></td>
		</tr>

        <tr>
			<td><div >Удаление ортодонтического импланта (винта)</div></td>
			<td><div className="td2" >1 500</div></td>
		</tr>

        <tr>
			<td><div >Удаление сверхкомплектного зуба с отслаиванием лоскута</div></td>
			<td><div className="td2" >10 000</div></td>
		</tr>

        <tr>
			<td><div >Удаление фрагмента зуба </div></td>
			<td><div className="td2" >1 500</div></td>
		</tr>

        <tr>
			<td><div >Удаление зуба (сложное)</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>

        <tr>
			<td><div >Удаление зуба сложное с разъединением корней</div></td>
			<td><div className="td2" >6 000</div></td>
		</tr>

        <tr>
			<td><div >Апекальная микрохирургия с ретроградным пломбированием фронтальных зубов</div></td>
			<td><div className="td2" >15 000</div></td>
		</tr>

        <tr>
			<td><div >Апикальная микрохирургия  с ретроградным пломбированием в области жевательного зуба</div></td>
			<td><div className="td2" >15 000</div></td>
		</tr>

        <tr>
			<td><div >Вскрытие периостита по переходной складке и дренирование для оттока экскудата</div></td>
			<td><div className="td2" >4 000</div></td>
		</tr>

        <tr>
			<td><div >Лечение альвеолита</div></td>
			<td><div className="td2" >1 500</div></td>
		</tr>

        <tr>
			<td><div >Удаление ретенционной кисты</div></td>
			<td><div className="td2" >15 000</div></td>
		</tr>

        <tr>
			<td><div ></div></td>
			<td><div className="td2" ></div></td>
		</tr>

        <tr>
			<td><div >Удаление фолликулярной кисты</div></td>
			<td><div className="td2" >10 270</div></td>
		</tr>

        <tr>
			<td><div ></div></td>
			<td><div className="td2" ></div></td>
		</tr>

        <tr>
			<td><div >Цистэктомия</div></td>
			<td><div className="td2" >6 310</div></td>
		</tr>

        <tr>
			<td><div >Комбинированная регенеративная методика костного дефекта по Тромбелли</div></td>
			<td><div className="td2" >26 780</div></td>
		</tr>

        <tr>
			<td><div >Гингивэктомия в области одного зуба</div></td>
			<td><div className="td2" >1 000</div></td>
		</tr>

        <tr>
			<td><div >Удлинение клинической высоты коронки (гингивэктомия в области одного зуба)</div></td>
			<td><div className="td2" >3 500</div></td>
		</tr>

        <tr>
			<td><div >Открытый кюретаж в области одного зуба с удалением контаминированного слоя с поверхности корня</div></td>
			<td><div className="td2" >1 500</div></td>
		</tr>

        <tr>
			<td><div >Кюретаж пародонтального кармана</div></td>
			<td><div className="td2" >4 750</div></td>
		</tr>

        <tr>
			<td><div >Лоскутная операция (по Рамфьорд) в области одного зуба (гингивотомия, кюретаж со шлифовкой и полировкой корня зуба и закрытием костного кармана остеотропным препаратом), обработка корня </div></td>
			<td><div className="td2" >18 920</div></td>
		</tr>

        <tr>
			<td><div >Редукция альвеолярного экзостоза</div></td>
			<td><div className="td2" >4 420</div></td>
		</tr>

        <tr>
			<td><div >Использование  коллагеновой мембраны 25х25 </div></td>
			<td><div className="td2" >24 120</div></td>
		</tr>

        <tr>
			<td><div >Использование графта 2 гр.</div></td>
			<td><div className="td2" >28 340</div></td>
		</tr>

        <tr>
			<td><div >Использование коллагеновой мембраны 13х25 </div></td>
			<td><div className="td2" >16 060</div></td>
		</tr>

        <tr>
			<td><div >Использование коллагеновой мембраны 30х40 </div></td>
			<td><div className="td2" >32 110</div></td>
		</tr>

        <tr>
			<td><div >Удаление мембраны</div></td>
			<td><div className="td2" >2 860</div></td>
		</tr>

        <tr>
			<td><div >Пластика уздечки губы</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>

        <tr>
			<td><div >Пластика  уздечки языка</div></td>
			<td><div className="td2" >8 000</div></td>
		</tr>

        <tr>
			<td><div >Установка одного ортодонтического винта простое</div></td>
			<td><div className="td2" >9 000</div></td>
		</tr>

        <tr>
			<td><div >Изготовление стереолитографического 3D  шаблона для установки импланта</div></td>
			<td><div className="td2" >14 170</div></td>
		</tr>

        <tr>
			<td><div >Изготовление стереолитографического 3D  шаблона для установки импланта  при полной адентии</div></td>
			<td><div className="td2" >23 600</div></td>
		</tr>

        <tr>
			<td><div ></div></td>
			<td><div className="td2" ></div></td>
		</tr>

        <tr>
			<td><div >Прямое клиническое изготовление хирургического шаблона для установки импланта на 1 челюсть</div></td>
			<td><div className="td2" >4 420</div></td>
		</tr>

        <tr>
			<td><div >Иссечение слизистого "капюшона" </div></td>
			<td><div className="td2" >2 000</div></td>
		</tr>

        <tr>
			<td><div >Иссечение слизистого "капюшона" в области зуба мудрости</div></td>
			<td><div className="td2" >2 500</div></td>
		</tr>

        <tr>
			<td><div >Забор соединительнотканного десневого трансплантата с бугра верхней челюсти</div></td>
			<td><div className="td2" >6 000</div></td>
		</tr>

        <tr>
			<td><div >Забор соединительнотканного десневого трансплантата с нёба</div></td>
			<td><div className="td2" >7 090</div></td>
		</tr>

        <tr>
			<td><div >Закрытие рецессии в области двух и более недостающих зубов</div></td>
			<td><div className="td2" >25 220</div></td>
		</tr>

        <tr>
			<td><div >Закрытие рецессии в области одного зуба</div></td>
			<td><div className="td2" >14 170</div></td>
		</tr>

        <tr>
			<td><div >Контурная пластика с использованием парадонтологического шаблона</div></td>
			<td><div className="td2" >23 600</div></td>
		</tr>

        <tr>
			<td><div >Повторная коррекция клинической высоты коронки  в области одного зуба</div></td>
			<td><div className="td2" >1 000</div></td>
		</tr>

        <tr>
			<td><div >Лоскутная операция по изменению контура десны с коррекцией костных структур (гингивотомия, редуцирование, кюретаж с обработкой корня), коррекция клинической длины коронки</div></td>
			<td><div className="td2" >11 050</div></td>
		</tr>

        <tr>
			<td><div >Удаление новообразования слизистой</div></td>
			<td><div className="td2" >2 000</div></td>
		</tr>

        <tr>
			<td><div >Пластика ороантрального сообщения</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>

        <tr>
			<td><div >Наложение глубокого шва резорбируемой  нитью </div></td>
			<td><div className="td2" >1 110</div></td>
		</tr>

        <tr>
			<td><div >Наложение швов в области одного зуба/импланта/лунки</div></td>
			<td><div className="td2" >1 000</div></td>
		</tr>

        <tr>
			<td><div >Наложение швов в одном сегменте</div></td>
			<td><div className="td2" >2 000</div></td>
		</tr>
        <tr>
			<td><div >Снятие шва в области одного зуба/импланта</div></td>
			<td><div className="td2" >520</div></td>
		</tr>
        <tr>
			<td><div >Снятие швов в одном сегменте</div></td>
			<td><div className="td2" >650</div></td>
		</tr> */}

		

	</tbody>
</table>
</Container>              
               
        
        </>
    )
}
export default Hirurg