import React from "react";
import "./Nav.scss";

import Menu from "../Menu/Menu";
import { Link } from "react-router-dom";

const Nav = () =>{
    return(
<>
{/* Mobile */}
<div className="MobWrap1">
    <Link to="/"><img width={260} src="/img/logo6.png" alt=""/></Link>
    </div>

    {/* desktop */}
<div className="wrap">
    <div className="wrap1">
        <div className="wrap11">
        <img src="/img/clock.svg" className={"mr-3"} width={15} alt=""/>
        <span>пн-пт 08:00—21:00</span>
        </div>
        <div className="wrap12">
        <img src="/img/clock.svg" className={"mr-3"} width={15} alt=""/>
        <span>сб-вс 08:00—21:00</span>
        </div>
    </div>

    <div>
    <Link to="/">  <img width={260} src="/img/logo6.png" alt=""/></Link>
    
    </div>
    <div className="wrap2">
        <div className="wrap21">
        <img src="/img/pip.svg" className={"mr-3"} width={15} alt=""/>
        <span> ул. Интернациональная, 92</span>
       </div>
        <div className="wrap22">
        <img src="/img/call.svg" className={"mr-3"} width={15} alt=""/>
        <span>+7 (4752) 55-95-32</span>
        </div>
        </div>
</div>

    <Menu/>

      </>

    )
}

export default Nav
