import React from "react";
import "./Ortoped.scss";
import { Container, Row, Col } from "react-grid-system";
import { Helmet } from "react-helmet";
const Ortoped = () =>{
    return(
		<>
		<Helmet>
			<title>Ортопедия</title>
		</Helmet>
                <div className="ort1">
        <h1>Наши услуги</h1>
        <span className="animateСharcterO">Ортопедия</span>
     
        </div>
        <Container>
            <Row>
                <Col lg={4}>
					<div className="ort2">
					<img src="/img/doc.JPG"  alt="image" />
					</div>
					</Col>

                <Col lg={8}>
					<div className="ort22">
					<p>Здоровая и привлекательная улыбка – это не просто важная составляющая хорошего самочувствия,
						 но и залог успеха в жизни. В нашей стоматологической клинике мы предлагаем широкий спектр 
						 ортопедических услуг, которые помогут вам восстановить утраченные зубы и вернуть красоту и функциональность
						  вашей улыбке.
					</p>
<p>Наши высококвалифицированные специалисты используют передовые технологии и материалы, чтобы создать для вас идеальные 
	протезы, которые будут выглядеть естественно и служить долгие годы. Мы предлагаем различные виды ортопедических конструкций: 
	коронки, мосты, съемные и несъемные протезы, а также имплантаты, которые обеспечивают надежную фиксацию зубных протезов.
</p>
<p>Каждому пациенту мы уделяем индивидуальное внимание, тщательно изучая особенности его ротовой полости и учитывая все пожелания. 
	Наша цель – восстановить вашу способность комфортно жевать, улыбаться и общаться без стеснения.
	</p>

<p>Доверьте свою улыбку лучшим специалистам в области стоматологической ортопедии. Мы гарантируем
	 высокое качество работы, использование безопасных материалов и внимательное 
	отношение к каждому пациенту. Запишитесь на консультацию прямо сейчас и сделайте первый шаг к восстановлению
	 вашей красивой и здоровой улыбки!</p>
	 </div>
	 </Col>
				</Row>
				</Container>
				<Container>
					<Row>
                <Col lg={12}>

                <table className="tbort">
	<tbody>
	<tr>	
		
		<td colSpan={2} align="center">	<div className="td2" >Прейскурант цен ООО «Центр Современной Стоматологии» <br /> на терапевтические услуги</div></td>
	
	</tr>
	
	<tr>	
	
	<td colSpan={2} align="center">	<div className="td2" >Ортопедия</div></td>

</tr>

		<tr>
			<td><div >Консультация врача - специалиста</div></td>
			<td><div className="td2" >1 000</div></td>
		</tr>
		<tr>
			<td><div >Вкладка керамическая inlay/onlay</div></td>
			<td><div className="td2" >12 000</div></td>
		</tr>
		<tr>
			<td><div >Цифровая модель обоих рядов зубов ( 3D- сканирование)</div></td>
			<td><div className="td2" >2 500</div></td>
		</tr>	
		<tr>
			<td><div >Изготовление  и установка металлокерамической коронки</div></td>
			<td><div className="td2" >15 000</div></td>
		</tr>
			<tr>
			<td><div >Установка постоянной коронки диоксид циркония с опорой на собственный зуб </div></td>
			<td><div className="td2" >25 000</div></td>
		</tr>
		<tr>
			<td><div >Установка постоянной коронки диоксид циркония с прозрачным режущим краем  опорой на имплант с учетом анатомии и оттенков соседних зубов (винтовая фиксация)</div></td>
			<td><div className="td2" >30 000</div></td>
		</tr>
		<tr>
			<td><div >Изготовление пластмассового съемного протеза</div></td>
			<td><div className="td2" >25 000</div></td>
		</tr>
		<tr>
			<td><div >Изготовление бюгельного протеза с замковыми кламмерами</div></td>
			<td><div className="td2" >45 000</div></td>
		</tr>
		
		
        {/* <tr>
			<td><div >Изготовление цельнофрезерованного колпачка e.max</div></td>
			<td><div className="td2" >3 500</div></td>
		</tr>
		
		
        <tr>
			<td><div >Установка постоянной коронки диоксид циркония с опорой на имплант с учетом анатомии и оттенков соседних зубов (винтовая фиксация)</div></td>
			<td><div className="td2" >30 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Установка постоянной коронки диоксид циркония с опорой на собственный зуб</div></td>
			<td><div className="td2" >25 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Установка постоянной коронки диоксид циркония с прозрачным режущим краем опорой на имплант с учетом анатомии и оттенков соседних зубов (винтовая фиксация)</div></td>
			<td><div className="td2" >30 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Установка постоянной коронки диоксид циркония с прозрачным режущим краем с опорой на собственный зуб</div></td>
			<td><div className="td2" >27 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Установка провизорной коронки в промежуточной части протеза</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Установка провизорной коронки на имплант для формирования контура десны</div></td>
			<td><div className="td2" >7 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Установка провизорной коронки на собственный зуб</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Установка промежуточной части безметаллового протеза</div></td>
			<td><div className="td2" >25 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Цифровой Wax-Up за 1 единицу</div></td>
			<td><div className="td2" >1 000</div></td>
		</tr>
		
		
		<tr>
			<td><div >Установка постоянной коронки диоксид циркония с опорой на имплант Straumann с учетом анатомии и оттенков соседних зубов (винтовая фиксация)</div></td>
			<td><div className="td2" >34 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Установка провизорной коронки на имплант Straumann для формирования контура десны</div></td>
			<td><div className="td2" >9 900</div></td>
		</tr>
		
		
        <tr>
			<td><div >Адгезивная фиксация ортопедической конструкции 1 единица</div></td>
			<td><div className="td2" >850</div></td>
		</tr>
		
		
        <tr>
			<td><div >Вкладка керамическая inlay/onlay</div></td>
			<td><div className="td2" >12 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Восковое моделирование коронковой части зуба (wax-up) до 6 зубов 1 ед.</div></td>
			<td><div className="td2" >1 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Восковое моделирование коронковой части зуба (wax-up) свыше 7 зубов за 1 ед.</div></td>
			<td><div className="td2" >700</div></td>
		</tr>
		
		
        <tr>
			<td><div >Временная фиксация ортопедической конструкции (1 опора)</div></td>
			<td><div className="td2" >200</div></td>
		</tr>
		
		
        <tr>
			<td><div >Замена матрицы в замковом бюгельном протезе</div></td>
			<td><div className="td2" >2 100</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление альгинатного слепка одной челюсти</div></td>
			<td><div className="td2" >1 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление бюгельного протеза с замковыми кламмерами</div></td>
			<td><div className="td2" >45 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление бюгельного протеза с назубными кламмерами</div></td>
			<td><div className="td2" >35 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление временного съёмного частичного иммедиат-протеза</div></td>
			<td><div className="td2" >25 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление временной коронки доктором в клинике</div></td>
			<td><div className="td2" >2 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление гипсовой модели</div></td>
			<td><div className="td2" >1 200</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление зуба цельнолитого на недрагоценном сплаве</div></td>
			<td><div className="td2" >7 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление и установка  металлической культевой вкладки неразборной</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление и установка косметической пластинки до 7 зубов</div></td>
			<td><div className="td2" >15 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление и установка косметической пластинки на 1 зуб</div></td>
			<td><div className="td2" >10 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление и установка металлической культевой вкладки разборной</div></td>
			<td><div className="td2" >6 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление индивидуального абатмента</div></td>
			<td><div className="td2" >10 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление индивидуальной слепочной ложки</div></td>
			<td><div className="td2" >1 500</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление каппы</div></td>
			<td><div className="td2" >1 950</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление каппы для коррекции прикуса в лаборатории</div></td>
			<td><div className="td2" >3 640</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление керамического винира</div></td>
			<td><div className="td2" >25 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление керамической коронки</div></td>
			<td><div className="td2" >25 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление коронки цельнолитой на недрагоценном сплаве</div></td>
			<td><div className="td2" >7 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление металлокерамической коронки</div></td>
			<td><div className="td2" >15 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление металлокерамической коронки с замковым креплением для бюгельного протеза</div></td>
			<td><div className="td2" >15 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление одностороннего бюгельного протеза с замковым креплением</div></td>
			<td><div className="td2" >30 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление окклюзионного слепка</div></td>
			<td><div className="td2" >500</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление пластмассового съемного протеза</div></td>
			<td><div className="td2" >25 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление прикусного шаблона</div></td>
			<td><div className="td2" >1 500</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление слепка одной  челюсти С- силиконом</div></td>
			<td><div className="td2" >1 500</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление слепка одной челюсти А- силиконом</div></td>
			<td><div className="td2" >1 800</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление спортивной каппы</div></td>
			<td><div className="td2" >10 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление термопластичного съемного протеза</div></td>
			<td><div className="td2" >30 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Изготовление цельнолитой коронки на недрагоценном сплаве</div></td>
			<td><div className="td2" >7 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Индивидуализация трансфера</div></td>
			<td><div className="td2" >2 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Коррекция условно-съемного протеза</div></td>
			<td><div className="td2" >1 500</div></td>
		</tr>
		
		
        <tr>
			<td><div >Несъемный протез с опорой на импланты (временный)</div></td>
			<td><div className="td2" >70 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Перебазировка съемного протеза</div></td>
			<td><div className="td2" >3 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Разделение литой навесной части протеза</div></td>
			<td><div className="td2" >1 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Ремонт кламмера</div></td>
			<td><div className="td2" >3 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Ремонт постоянного протеза с опорой на 6 имплантатах Nobel Biocare</div></td>
			<td><div className="td2" >7 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Ремонт съемного протеза (соединение сломанных частей)</div></td>
			<td><div className="td2" >3 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Ремонт съемного протеза (установка зуба)</div></td>
			<td><div className="td2" >3 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Ремонт съемного протеза (установка кламмера)</div></td>
			<td><div className="td2" >3 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Снятие  коронки из диоксида циркония</div></td>
			<td><div className="td2" >2 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Снятие слепка для последующего протезирования с уровня мультиюнита</div></td>
			<td><div className="td2" >4 500</div></td>
		</tr>
		
		
        <tr>
			<td><div >Снятие слепка с использованием трансфера и аналога импланта</div></td>
			<td><div className="td2" >4 500</div></td>
		</tr>
		
		
        <tr>
			<td><div >Снятие толстостенной коронки</div></td>
			<td><div className="td2" >1 100</div></td>
		</tr>
		
		
        <tr>
			<td><div >Снятие тонкостенной коронки</div></td>
			<td><div className="td2" >500</div></td>
		</tr>
		
		
        <tr>
			<td><div >Создание искусственной десны в области одного зуба</div></td>
			<td><div className="td2" >1 100</div></td>
		</tr>
		
		
        <tr>
			<td><div >Фиксация постоянной коронки Fuji 1</div></td>
			<td><div className="td2" >400</div></td>
		</tr>
		
		
        <tr>
			<td><div >Фиксация постоянной коронки Fuji Plus</div></td>
			<td><div className="td2" >600</div></td>
		</tr>
		
		
        <tr>
			<td><div >Фиксация постоянной коронки на имплант</div></td>
			<td><div className="td2" >600</div></td>
		</tr>
		
		
        <tr>
			<td><div >Цифровая модель обоих рядов зубов ( 3D- сканирование)</div></td>
			<td><div className="td2" >2 500</div></td>
		</tr>
		
		
        <tr>
			<td><div >Цифровая модель обоих рядов зубов ( 3D- сканирование) для протезирования</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Экструзия зуба</div></td>
			<td><div className="td2" >7 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Эстетическая стеклокерамическая реставрациия зуба (Celtra press)</div></td>
			<td><div className="td2" >25 000</div></td>
		</tr>
		
		
        <tr>
			<td><div >Эстетическое моделирование будущей реставрации в лаборатории</div></td>
			<td><div className="td2" >2 000</div></td>
		</tr>
		<tr>
			<td><div >Эстетическое моделирование будущей реставрации в лаборатории</div></td>
			<td><div className="td2" >2 000</div></td>
		</tr> */}
        
	</tbody>
	
</table>

                </Col>
            </Row>
		
        </Container>
		</>

    )
}
export default Ortoped;