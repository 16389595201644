import React from "react";
import "./Uslugi.scss";
import {Col, Container, Row} from "react-grid-system";
import {Link} from "react-router-dom";
import Typewriter from "typewriter-effect";
import { Helmet } from "react-helmet";
import { useMediaQuery } from 'react-responsive'
const Uslugi = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1225px)'
    });

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    return (
        
            <>
        <Helmet>
            <title>Услуги</title>
        </Helmet>
        {isDesktopOrLaptop && <>
          <div className="uw">
                <h3 className="animateСharcter"> Услуги</h3>
            </div>


            <Container>
                {/*w1*/}
                
                <div data-aos="fade-left" data-aos-duration="900" data-aos-delay="200">
             
                <Row>
                    <Col xl={6} lg={6} md={6}>
                        
                        <img className="uslImg" src="/img/Uslug/v2/8.png"/>
                    </Col>
                       
                    <Col  xl={6} lg={6} md={6}>
                        <Row>
                                                      
                            <Col Align="center" md={12} lg={12} xl={12}>
                            <div className="uw9">
                            <img src="/img/ico/Group2.png" width={50}/>
                            
                                <Link className="podr" to="/gigien">Профессиональная гигиена</Link>
                           
<p className="usText">Хотите иметь белоснежную улыбку и здоровые зубы? Тогда приходите в нашу 
    стоматологическую клинику, где мы предлагаем профессиональное отбеливание 
    и гигиену для вашей улыбки!</p>
                                <Link className="podr" to="/gigien"><img src="/img/arrow.png" width={80}/></Link>
                                </div>
                            </Col>
                            </Row>
                            </Col>
                           
                </Row>
              
                </div>  
                {/*  */}
                <div data-aos="fade-right" data-aos-duration="900" data-aos-delay="200">
                <Row>

                    <Col xl={6} lg={6} md={6}>
                        <Row>
                            <Col Align="center"  md={12} lg={12} xl={12}>
                            <div className="uw9">
                            <img src="/img/ico/zubdel.png" width={45}/>
                                <Link className="podr" to="/terap">Терапевтические услуги </Link>
                                <p className="usText">
                                В нашей стоматологической клинике мы предлагаем широкий спектр
                                 терапевтических услуг. Наши высококвалифицированные
                                   стоматологи-терапевты используют передовые методики и современное оборудование,
                                    чтобы обеспечить максимальную эффективность лечения.
                                </p>
                                <Link className="podr" to="/terap"><img src="/img/arrow.png" width={80}/></Link>
                                </div>
                            </Col>

                        </Row>
                    </Col>


                    <Col xl={6} lg={6} md={6}>
                    {/* <img className="uslImg" src="/img/Uslug/v2/5.png"/> */}
                    <img className="uslImg" src="/img/Uslug/v2/1.png"/>
                    </Col>
                </Row>
                </div>

               {/*  */}
               <div data-aos="fade-left" data-aos-duration="900" data-aos-delay="200">
                <Row>
                    <Col  xl={6} lg={6} md={6}>
                       
                        <img className="uslImg" src="/img/Uslug/v2/2.png"/>
                    </Col>

                    <Col xl={6} lg={6} md={6}>
                        <Row>
                            <Col Align="center" md={12} lg={12} xl={12}>
                            <div className="uw9">
                            <img src="/img/ico/zub.png" width={50}/>
                                <Link className="podr" to="/ortoped">Ортопедия</Link>
                                <p className="usText">
                                Наши специалисты предложат Вам широкий выбор методик 
                                восстановления частично разрушенных зубов,  включая установку коронки на зуб
                                 «под ключ» - одно из самых популярных и доступных решений.
                                </p>
                                <Link className="podr" to="/ortoped"><img src="/img/arrow.png" width={80}/></Link>
                                </div>
                            </Col>
                </Row>
                    </Col>
                </Row>
                </div>
               
            {/*    w2*/}
            <div data-aos="fade-right" data-aos-duration="900" data-aos-delay="200">
                <Row>

                    <Col xl={6} lg={6} md={6}>
                        <Row>
                            <Col Align="center" md={12} lg={12} xl={12}>
                            <div className="uw9">
                            <img src="/img/ico/implant.png" width={50}/>
                                <Link className="podr" to="/impl">Имплантация
                            зубов</Link>
                        <p className="usText">Имплантация зубов – одна из самых востребованных
                            процедур современной стоматологии.
                            Она постепенно приходит на смену другим видам протезирования.</p>
                            <Link className="podr" to="/impl"><img src="/img/arrow.png" width={80}/></Link>
                            </div>
                        </Col>
                        </Row>
                    </Col>


                    <Col xl={6} lg={6} md={6}>
                        <img className="uslImg" src="/img/Uslug/v2/10.png"/>
                    </Col>
                </Row>
                </div>

            {/*    w3*/}
           
             {/*  */}
            <div data-aos="fade-left" data-aos-duration="900" data-aos-delay="200">
                <Row>
                    <Col xl={6} lg={6} md={6}>
                        <img className="uslImg" src="/img/Uslug/v2/3.png"/>
                    </Col>

                    <Col xl={6} lg={6} md={6}>
                        <Row>
                             
                             <Col Align="center"  md={12} lg={12} xl={12}>
                             <div className="uw9">
                             <img src="/img/ico/tooth.png" width={50}/>
                            
                                <Link className="podr" to="/ordont">Ортодонтия</Link>
                                <p className="usText">В нашей стоматологической клинике мы понимаем 
                         важность идеального прикуса и красивой улыбки. Поэтому наши высококвалифицированные ортодонты предлагают современные
                          и эффективные решения для исправления прикуса и выравнивания зубов.</p>
                                <Link className="podr" to="/ordont"><img src="/img/arrow.png" width={80}/></Link>
                                </div>
                            </Col>
                </Row>
                    </Col>
                </Row>
                </div>


            {/*    w4*/}
            <div data-aos="fade-right" data-aos-duration="900" data-aos-delay="200">
                <Row>

                    <Col xl={6} lg={6} md={6}>
                        <Row>
                            <Col Align="center" md={12} lg={12} xl={12}>
                            <div className="uw9">
                            <img src="/img/ico/Group1.png" width={50}/>
                                <Link className="podr" to="/hirurg">Хирургические услуги </Link>
                             <p className="usText">Профессиональная команда хирургов-стоматологов
                              нашей клиники готова предложить вам самые современные и безопасные
                               хирургические вмешательства. Мы используем новейшие методики и оборудование,
                                чтобы обеспечить максимальный комфорт 
                             и минимальный риск для наших пациентов.
                             </p>
                                <Link to="/hirurg" className="podr"><img src="/img/arrow.png" width={80}/></Link>
                                </div>
                            </Col>
                        </Row>
                    </Col>


                    <Col xl={6} lg={6} md={6}>
                    <img className="uslImg" src="/img/Uslug/v2/7.png"/> 
                    </Col>



                </Row>
            </div>
                {/*w5*/}
                <div data-aos="fade-left" data-aos-duration="900" data-aos-delay="200">
                <Row>
                    <Col xl={6} lg={6} md={6}>
                    <img className="uslImg" width="540px" src="/img/Uslug/v2/9.png" alt=""/>
                    </Col>

                    <Col xl={6} lg={6} md={6}>
                        <Row>
                             
                             <Col Align="center"  md={12} lg={12} xl={12}>
                             <div className="uw9">
                             <img src="/img/ico/tooth.png" width={50}/>
                            
                                <Link className="podr" to="/laboratory">Цифровая зуботехническая лаборатория</Link>
                                <p className="usText"></p>
                                <Link className="podr" to="/laboratory"><img src="/img/arrow.png" width={80}/></Link>
                                </div>
                            </Col>
                </Row>
                    </Col>
                </Row>
                </div>
                <div className="uslup"></div>
               
            </Container>


        </>
    }


{/* mobile */}





{isTabletOrMobile &&
<>
<div className="uw">
                <h3 className="animateСharcter"> Услуги</h3>
            </div>


                {/*w1*/}
                
                <div className="usWrapMob">
             
                <Row>
                    <Col  sm={12} md={12}>
                    <img className="uslImg1" src="/img/Uslug/v2/8.png"/>
                    </Col>
                       
                    <Col sm={12} md={12}>
                        <Row>
                                                      
                            <Col Align="center" md={12} lg={12} xl={12}>
                            <div className="uw9">
                            <img src="/img/ico/Group2.png" width={50}/>
                            
                                <Link className="podr" to="/gigien">Профессиональная гигиена</Link>
                           
<p className="usText1">Хотите иметь белоснежную улыбку и здоровые зубы? Тогда приходите в нашу 
    стоматологическую клинику, где мы предлагаем профессиональное отбеливание 
    и гигиену для вашей улыбки!</p>
                                <Link className="podr" to="/gigien"><img src="/img/arrow.png" width={80}/></Link>
                                </div>
                            </Col>
                            </Row>
                            </Col>
                           
                </Row>
              
           
                {/*  */}
                
                <Row>
                <Col sm={12} md={12}>
                <img className="uslImg1" src="/img/Uslug/v2/1.png"/>
                    </Col>
                    <Col sm={12} md={12}>
                        <Row>
                            <Col Align="center"  md={12} lg={12} xl={12}>
                            <div className="uw9">
                            <img src="/img/ico/zubdel.png" width={45}/>
                                <Link className="podr" to="/terap">Терапевтические услуги </Link>
                                <p className="usText1">
                                В нашей стоматологической клинике мы предлагаем широкий спектр
                                 терапевтических услуг. Наши высококвалифицированные
                                   стоматологи-терапевты используют передовые методики и современное оборудование,
                                    чтобы обеспечить максимальную эффективность лечения.
                                </p>
                                <Link className="podr" to="/terap"><img src="/img/arrow.png" width={80}/></Link>
                                </div>
                            </Col>

                        </Row>
                    </Col>


                   
                </Row>
             

               {/*  */}
               
                <Row>
                    <Col  sm={12} md={12}>  
                        <img className="uslImg1" src="/img/Uslug/v2/2.png"/>
                    </Col>

                    <Col sm={12} md={12}>
                        <Row>
                            <Col Align="center" md={12} lg={12} xl={12}>
                            <div className="uw9">
                            <img src="/img/ico/zub.png" width={50}/>
                                <Link className="podr" to="/ortoped">Ортопедия</Link>
                                <p className="usText1">
                                Наши специалисты предложат Вам широкий выбор методик
                                 восстановления частично разрушенных зубов,  включая установку
                                  коронки на зуб «под ключ» -
                                 одно из самых популярных и доступных решений.
                                </p>
                                <Link className="podr" to="/ortoped"><img src="/img/arrow.png" width={80}/></Link>
                                </div>
                            </Col>
                </Row>
                    </Col>
                </Row>
              
               
            {/*    w2*/}
            
                <Row>

                <Col sm={12} md={12}>
                        <img className="uslImg1" src="/img/Uslug/v2/10.png"/>
                    </Col>
                    <Col sm={12} md={12}>
                        <Row>
                            <Col Align="center" md={12} lg={12} xl={12}>
                            <div className="uw9">
                            <img src="/img/ico/implant.png" width={50}/>
                                <Link className="podr" to="/impl">Имплантация
                            зубов</Link>
                        <p className="usText1">Имплантация зубов – одна из самых востребованных
                            процедур современной стоматологии.
                            Она постепенно приходит на смену другим видам протезирования.</p>
                            <Link className="podr" to="/impl"><img src="/img/arrow.png" width={80}/></Link>
                            </div>
                        </Col>
                        </Row>
                    </Col>


                </Row>
              

            {/*    w3*/}
           
             {/*  */}
            
                <Row>
                    <Col sm={12} md={12}>
                        <img className="uslImg1" src="/img/Uslug/v2/3.png"/>
                    </Col>

                    <Col sm={12} md={12}>
                        <Row>
                             
                             <Col Align="center"  md={12} lg={12} xl={12}>
                             <div className="uw9">
                             <img src="/img/ico/tooth.png" width={50}/>
                            
                                <Link className="podr" to="/ordont">Ортодонтия</Link>
                                <p className="usText1">В нашей стоматологической клинике мы понимаем 
                         важность идеального прикуса и красивой улыбки. Поэтому наши высококвалифицированные ортодонты предлагают современные
                          и эффективные решения для исправления прикуса и выравнивания зубов.</p>
                                <Link className="podr" to="/ordont"><img src="/img/arrow.png" width={80}/></Link>
                                </div>
                            </Col>
                </Row>
                    </Col>
                </Row>



            {/*    w4*/}
            
                <Row>
                <Col sm={12} md={12}>
                    <img className="uslImg1" src="/img/Uslug/v2/7.png"/> 
                    </Col>
                    <Col sm={12} md={12}>
                        <Row>
                            <Col Align="center" md={12} lg={12} xl={12}>
                            <div className="uw9">
                            <img src="/img/ico/Group1.png" width={50}/>
                                <Link className="podr" to="/hirurg">Хирургические услуги </Link>
                             <p className="usText1">Профессиональная команда хирургов-стоматологов
                              нашей клиники готова предложить вам самые современные и безопасные
                               хирургические вмешательства. Мы используем новейшие методики и оборудование,
                                чтобы обеспечить максимальный комфорт 
                             и минимальный риск для наших пациентов.
                             </p>
                                <Link to="/hirurg" className="podr"><img src="/img/arrow.png" width={80}/></Link>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            
                {/*w5*/}
                
                <Row>
                    <Col sm={12} md={12}>
                        <img className="uslImg1" src="/img/Uslug/v2/9.png" alt=""/>
                    </Col>

                    <Col sm={12} md={12}>
                        <Row>
                             
                             <Col Align="center"  md={12} lg={12} xl={12}>
                             <div className="uw9">
                             <img src="/img/ico/tooth.png" width={50}/>
                            
                                <Link className="podr" to="/laboratory">Цифровая зуботехническая лаборатория</Link>
                                <p className="usText"></p>
                                <Link className="podr" to="/laboratory"><img src="/img/arrow.png" width={80}/></Link>
                                </div>
                            </Col>
                </Row>
                    </Col>
                </Row>
              
   <Container>            <div className="uslup"></div>
   </Container>

            </div>

</>
}
    </>
    )
}
export default Uslugi