import React from "react";
import { Row, Container, Col } from "react-grid-system";
import "./DocDrushkina.scss";
import AOS from "aos";
import 'aos/dist/aos.css';
import doc from "./doc.png";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import { useMediaQuery } from 'react-responsive';
import PhotoAlbum from "react-photo-album";
import photosDrush from "./photosDrush";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const DocDrushkina = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1225px)'
    });
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    useEffect(() => {
        AOS.init();
    }, []);
    return(
        <>
        <Helmet>
        <title>Дружкина Виктория Николаевна</title>
    </Helmet>
    {isDesktopOrLaptop && <>
        <div className="DocDrushkinaW1">
            <div className="doc1">
            <h1 className="animateDoc1">Дружкина Виктория Николаевна</h1>
            </div>
<Container>
    <Row>
        <Col xxxl={6} xxl={6} xl={6}>
        <div className="absolute inset-0 flex items-center justify-center " data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
      <div className="DocDrushkinaW2"> 
      {/* text */}
     <b> Воронежский Государственный Медицинский Университет им. Н.Н. Бурденко</b> <br />
<div className="borleft">Стоматология  <br />
<span>2010</span> <br />
Высшее образование <br />
</div>
<div className="text-center">
<b>Повышение квалификации</b> <br />
</div>
<div className="borleft">
«Трёхмерная диагностика челюстно-лицевой области», Рогацкин Д.В., Тамбов <br />
<span>2019</span> <br />
Научно-практический семинар «Профилактика и лечение неотложных состояний у пациентов в условиях амбулаторного приёма», Тамбов <br />
<span>2019</span> <br />
«Ошибки и осложнения эндодонтического лечения. Тактика и профилактика», Афанютин А.П., Воронеж <br />
<span>2020</span> <br />
«Трёхмерная диагностика челюстно-лицевой области. Специализированный курс ч.1», Рогацкин Д.В., Тамбов <br />
<span>2021</span> <br />
«Реставрации боковой группы зубов», Беретарь Р., Dentalworkshop, Москва <br />
<span>2021</span> <br />
«Секреты реставрации фронтальной группы зубов», Василиадис Р. А., Саратов <br />
<span>2021</span> <br />
«Неотложные состояния в практике врача-стоматолога», Воронеж <br />
<span>2021</span> <br />
«Пародонтологический практикум-интенсив», STI, Приямпольская М.Б., Москва <br />
<span>2022</span> <br />
«Доказательная пародонтология. Протоколы без суеверий», STI, Приямпольская М.Б., Москва <br />
<span>2022</span> <br />
«Инструментальная обработка корневых каналов», Ребриев Е.Ю., Воронеж <br />
<span>2022</span> <br />
«Лечение постоянных зубов с несформированным корнем у детей и подростков. Эндодонтическое лечение травмированных зубов», Болячин А.В., Москва <br />
<span>2023</span> <br />
</div>
      </div>
        </div>
        </Col>
        <Col xxxl={6} xxl={6} xl={6}>
        <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
            <img src={doc}/>
            </div>
        </Col>
    </Row>
</Container>

        </div>

     <div className="DocDrushkinaW1">
            <div className="doc1">
            <h1 className="animateDoc12">Сертификаты</h1>
            </div> 
</div>

            <Container>
  <PhotoAlbum layout="columns" photos={photosDrush} />;
  </Container>

  </>}





  {isTabletOrMobile &&<>
    <div className="DocDrushkinaW1">
            <div className="doc1">
            <h1 className="animateDoc1">Дружкина Виктория Николаевна</h1>
            </div>
<Container>
    <Row>
<Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12}>
        <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
            <img src={doc}/>
            </div>
        </Col>
        </Row>
    <Row>
    <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12}>
        <div className="flex items-center justify-center " data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
      <div className="DocDrushkinaW2"> 
      {/* text */}
      
      </div>
        </div>
        </Col>
      
    </Row>
</Container>

        </div>

     <div className="DocDrushkinaW1">
            <div className="doc1">
            <h1 className="animateDoc12">Сертификаты</h1>
            </div> 
</div>
      
 

  <Container>
  <PhotoAlbum layout="columns" photos={photosDrush} />;
  </Container>
  </>}
</>
    )
}
export default DocDrushkina;