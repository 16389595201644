import React from "react";
import Nav from "./components/Nav/Nav";
import Rout from "./components/Rout/Rout";
import ScrollToTop from "react-scroll-to-top";
import ScrollToTopT from "./components/ScrolToTop/ScrolToTop";
import { Helmet } from "react-helmet";
const App = () => {

  return(
      <>
      <Helmet>
        <title>Центр Современной Стоматологии</title>
      </Helmet>
      <title>Центр современной стоматологии</title>
      <ScrollToTopT>
       <Nav/>

      
{/* <ScrollToTop smooth 
            style={{backgroundColor:'rgba(0,0,0,0)'}}
            width="28"
            height="28"/> */}

<ScrollToTop smooth 
style={{backgroundColor:'rgba(0,0,0,0)',boxShadow:"none"}}
         
width="28"
height="28"
/>
</ScrollToTopT>
      </>
  );
}

export default App;
