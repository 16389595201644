
const photos = [
 

    {
      src: "/img/DocHabibulin/cert/2.jpg",
      width: 3,
      height: 4
    },
 
    {
      src: "/img/DocHabibulin/cert/3.jpg",
      width: 3,
      height: 4
    },
       
    
       {
      src: "/img/DocHabibulin/cert/7.jpg",
      width: 4,
      height: 3
    },
       {
      src: "/img/DocHabibulin/cert/8.jpg",
      width: 3,
      height: 4
    },
       {
      src: "/img/DocHabibulin/cert/9.jpg",
      width: 4,
      height: 3
    },
    
  ];
  export default photos;
  