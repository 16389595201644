 import React from "react";
 import { Row, Container, Col } from "react-grid-system";
 import "./DocGukasyan.scss";
 import AOS from "aos";
 import 'aos/dist/aos.css';
 import doc from "./doc.png";
 import { useEffect } from "react";
 import { Helmet } from "react-helmet";
 import PhotoAlbum from "react-photo-album";
 import { useMediaQuery } from 'react-responsive';
import photos from "./photos";
 const DocGukasyan = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1225px)'
    });
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

     useEffect(() => {
         AOS.init();
     }, []);
     return(
         <>
         <Helmet>
         <title>Гукасян Вачик Артушевич</title>
     </Helmet>
     {isDesktopOrLaptop && <>
         <div className="DocHabibulinW1">
             <div className="doc1">
             <h1 className="animateDoc1">Гукасян Вачик Артушевич</h1>
             </div>
 <Container>
     <Row>
         <Col xxxl={5} xxl={5} xl={5}>
         <div className="absolute inset-0 flex items-center justify-center " data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
       <div className="DocGukasyanW2">  
       <b>Воронежская государственная медицинская академия им. Н.Н. Бурденко</b> <br />
<div className="borleft">  <span>2013</span> <br />
Стоматология <br />
<span>Высшее образование</span> <br />
</div>
Московский государственный медико-стоматологический университет Евдокимова <br />
<div className="borleft">
<span>2015</span> <br />
Стоматология общей практики <br />
<span>Ординатура</span> <br />
</div>
Институт медико-социальных технологий ФГБОУ ВО «МГУПП» <br />
<div className="borleft">
 <span>2016</span> <br />
Стоматология хирургическая <br />
<span>Повышение квалификации</span> <br />
</div>

Воронежский Государственный Медицинский Университет им. Н.Н. Бурденко <br />
<div className="borleft">
<span>2016</span> <br />
Стоматология терапевтическая  <br />
<span>Повышение квалификации</span> <br />
</div>
ООО «Рудента Фэмили» <br />
<div className="borleft">
<span>2020</span> <br />
Стоматология хирургическая <br />
<span>Повышение квалификации</span> <br />
</div>
 
с 2013 (стаж 11 лет)
       </div>
         </div>
         </Col>
         <Col xxxl={7} xxl={7} xl={7}>
         <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
             <img src={doc}/>
             </div>
         </Col>
     </Row>
 </Container>
         </div>
      <div className="DocHabibulinW1">
             <div className="doc1">
             <h1 className="animateDoc12">Сертификаты</h1>
             </div> 
 </div>
 <Container>
<PhotoAlbum layout="columns" photos={photos} />;
 </Container> 
        </>}
        {isTabletOrMobile &&<>
            <div className="DocHabibulinW1">
             <div className="doc1">
             <h1 className="animateDoc1">Гукасян Вачик Артушевич</h1>
             </div>
 <Container>
     <Row>
     <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12}>
     <div className="flex items-center justify-center " data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
             <img src={doc}/>
             </div>
             </Col>
             </Row>
             <Row>
             <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12}>
         <div className="flex items-center justify-center " data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
       <div className="DocGukasyanW2">  
       <b>Воронежская государственная медицинская академия им. Н.Н. Бурденко</b> <br />
<div className="borleft">  <span>2013</span> <br />
Стоматология <br />
<span>Высшее образование</span> <br />
</div>
Московский государственный медико-стоматологический университет Евдокимова <br />
<div className="borleft">
<span>2015</span> <br />
Стоматология общей практики <br />
<span>Ординатура</span> <br />
</div>
Институт медико-социальных технологий ФГБОУ ВО «МГУПП» <br />
<div className="borleft">
 <span>2016</span> <br />
Стоматология хирургическая <br />
<span>Повышение квалификации</span> <br />
</div>

Воронежский Государственный Медицинский Университет им. Н.Н. Бурденко <br />
<div className="borleft">
<span>2016</span> <br />
Стоматология терапевтическая  <br />
<span>Повышение квалификации</span> <br />
</div>
ООО «Рудента Фэмили» <br />
<div className="borleft">
<span>2020</span> <br />
Стоматология хирургическая <br />
<span>Повышение квалификации</span> <br />
</div>
 
с 2013 (стаж 11 лет)
       </div>
         </div>
         </Col>
         </Row>
         
  
 </Container>
         </div>
      <div className="DocHabibulinW1">
             <div className="doc1">
             <h1 className="animateDoc12">Сертификаты</h1>
             </div> 
 </div>
 <Container>
<PhotoAlbum layout="columns" photos={photos} />
 </Container> 
        
        </>}
 </>
     )
 }
 export default DocGukasyan;