
const photosReg = [
    {
      src: "/img/DocReg/cert1.jpg",
      width: 3,
      height: 4
    }, 
     {
      src: "/img/DocReg/cert2.jpg",
      width: 3,
      height: 4
    },    {
      src: "/img/DocReg/cert3.jpg",
      width: 3,
      height: 4
    },    {
      src: "/img/DocReg/cert4.jpg",
      width: 4,
      height: 3
    },    {
      src: "/img/DocReg/cert5.JPG",
      width: 3,
      height: 4
    },    {
      src: "/img/DocReg/cert6.JPG",
      width: 4,
      height: 3
    },    {
      src: "/img/DocReg/cert7.JPG",
      width: 4,
      height: 3
    },
     {
      src: "/img/DocReg/cert8.JPG",
      width: 4,
      height: 3
    },
];
export default photosReg;