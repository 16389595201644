import React from "react";
import { Row, Container, Col } from "react-grid-system";
import "./DocObolenkina.scss";
import AOS from "aos";
import 'aos/dist/aos.css';
import doc from "./doc.png";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import { useMediaQuery } from 'react-responsive';
import cert1 from "./cert1.jpg";
import cert2 from "./cert2.jpg";
import cert3 from "./cert3.jpg";
import cert4 from "./cert4.jpg";
import cert5 from "./cert5.jpg";
import cert6 from "./cert6.jpg";
import cert7 from "./cert7.jpg";
import cert8 from "./cert8.jpg";
import cert9 from "./cert9.jpg";
import cert10 from "./cert10.jpg";
const AutoplaySlider = withAutoplay(AwesomeSlider);

const DocObolenkina = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1225px)'
    });
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    useEffect(() => {
        AOS.init();
    }, []);
    return(
        <>
        <Helmet>
        <title>Оболенкина Ирина Алексеевна</title>
    </Helmet>
    {isDesktopOrLaptop && <>
        <div className="DocObolenkinaW1">
            <div className="doc1">
            <h1 className="animateDoc1">Оболенкина Ирина Алексеевна</h1>
            </div>
<Container>
    <Row>
        <Col xxxl={5} xxl={5} xl={5}>
        <div className="absolute inset-0 flex items-center justify-center " data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
      <div className="DocObolenkinaW2"> 
      {/* text */}
      <b>Тамбовский областной медицинский колледж</b>
<div className="borleft"> <span>2008</span> <br />
Сестринское дело <br />
<span>Среднее профессиональное образование</span> <br />
</div>
 
НОУ - Образовательный центр "Стоматологический колледж №1" г. Москва <br /> 
<div className="borleft"> <span>2013г</span>  <br />
Профессиональная переподготовка <br />
<span>"Стоматологическая профилактика" </span><br />
</div>
 
ФГБОУ ВО "Приволжский исследовательский медицинский университет" г. Нижний Новгород <br />
<div className="borleft">

<span>2018г.</span>
<span>"Стоматология профилактическая"</span>
</div> 
<div className="text-center">ПОВЫШЕНИЕ КВАЛИФИКАЦИИ</div>
Соловьева А.М.  Колгейт - Палмолив, г. Москва <br />
<div className="borleft">
<span>2013</span> <br />
<span>" Профилактика и лечение воспалительных заболеваний пародонта: эффективность средств с антибактериальным и противовоспалительным действием".
<br /></span>
</div>

Акулович А.В. DentalWebinar.ru
<div className="borleft">
<span>2018</span> <br />
<span>
"Отбеливание зубов. Неинвазивные методики устранения дисколоритов. Ремотерапия. Чувствительность зубов. Цвет в эстетической стоматологии"
</span> <br />
</div>

<div className="borleft">
<span>2018</span> <br />
<span> "Реминерализующая терапия в практике врача-стоматолога." </span> <br />
</div>
Бичикаева З.А. Philips Zoom, г. Москва. <br />
<div className="borleft">
<span>2019</span>
 <span>"Отбеливание зубов с применением технологии Philips Zoom WhiteSpeed".</span>
 </div>
 Тиунова Н.В. Учебный центр СПЕЦИАЛИСТ, г. Воронеж
<div className="borleft">
<span>2019 </span> <br />
<span>"Тактика врача- стоматолога при заболеваниях слизистой оболочки рта." </span> <br />
 </div>
 Малютин Д.В. Учебный центр СПЕЦИАЛИСТ, г. Воронеж <br />
 <div className="borleft">
<span>2019</span> <br />
<span>"Цифровая фотография в повседневной стоматологической практике". </span> <br />
</div>
Молчанов А.С. 3D-Stom, г. Тамбов <br />
<div className="borleft">
<span>2019</span>
<span>"Компьютерная томография в ежедневной практике врача - стоматолога". </span>
 </div>
 Соловьева И.В., Учебный центр ДЕНТЕКС, г. Москва. <br />
<div className="borleft">
<span>2020</span>
<span>"Теоретические и практические аспекты, принципы работы и особенности использования аппарата VECTOR ( Dull Dental )  в современной пародонтологии". 
</span>
</div>
Приямпольская М.Б., Учебный центр S.T.I. , г. Москва <br />
<span>2020</span> <br />
<span>"Пародонтологический практикум - интенсив" </span><br />
      </div>
        </div>
        </Col>
        <Col xxxl={7} xxl={7} xl={7}>
        <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
            <img src={doc}/>
            </div>
        </Col>
    </Row>
</Container>

        </div>

     <div className="DocObolenkinaW1">
            <div className="doc1">
            <h1 className="animateDoc12">Сертификаты</h1>
            </div> 
</div>
        <Container>
            <Row>
                <Col xl={2}></Col>
                <Col xl={8}>
                <AutoplaySlider
    play={true}
    cancelOnInteraction={false} 
    interval={6000}
    bullets={false}

    
  >
    <div data-src={cert1} />
    <div data-src={cert2} />
    <div data-src={cert3} /> 
     <div data-src={cert4} /> 
      <div data-src={cert5} />
      <div data-src={cert6} />
      <div data-src={cert7} />
      <div data-src={cert8} />
      <div data-src={cert9} />
      <div data-src={cert10} />
  </AutoplaySlider>
                </Col>
                <Col xl={2}></Col>
            </Row>
 
  </Container>
    
    </>}
    {/* mobile */}
    {isTabletOrMobile &&<>
        <div className="DocObolenkinaW1">
            <div className="doc1">
            <h1 className="animateDoc1">Оболенкина Ирина Алексеевна</h1>
            </div>
<Container>
    <Row>
    <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12}>
    
    <div className="flex items-center justify-center " data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
        <img src={doc}/>
        </div>
    </Col>
    <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12}>
     <div className="flex items-center justify-center " data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
      <div className="DocObolenkinaW2"> 
      {/* text */}
     <b> Тамбовский областной медицинский колледж</b>
<div className="borleft"> <span>2008</span> <br />
Сестринское дело <br />
<span>Среднее профессиональное образование</span> <br />
</div>
 
НОУ - Образовательный центр "Стоматологический колледж №1" г. Москва <br /> 
<div className="borleft"> <span>2013г</span>  <br />
Профессиональная переподготовка <br />
<span>"Стоматологическая профилактика" </span><br />
</div>
 
ФГБОУ ВО "Приволжский исследовательский медицинский университет" г. Нижний Новгород <br />
<div className="borleft">

<span>2018г.</span>
<span>"Стоматология профилактическая"</span>
</div> 
<div className="text-center">ПОВЫШЕНИЕ КВАЛИФИКАЦИИ</div>
Соловьева А.М.  Колгейт - Палмолив, г. Москва <br />
<div className="borleft">
<span>2013</span> <br />
<span>" Профилактика и лечение воспалительных заболеваний пародонта: эффективность средств с антибактериальным и противовоспалительным действием".
<br /></span>
</div>

Акулович А.В. DentalWebinar.ru
<div className="borleft">
<span>2018</span> <br />
<span>
"Отбеливание зубов. Неинвазивные методики устранения дисколоритов. Ремотерапия. Чувствительность зубов. Цвет в эстетической стоматологии"
</span> <br />
</div>

<div className="borleft">
<span>2018</span> <br />
<span> "Реминерализующая терапия в практике врача-стоматолога." </span> <br />
</div>
Бичикаева З.А. Philips Zoom, г. Москва. <br />
<div className="borleft">
<span>2019</span>
 <span>"Отбеливание зубов с применением технологии Philips Zoom WhiteSpeed".</span>
 </div>
 Тиунова Н.В. Учебный центр СПЕЦИАЛИСТ, г. Воронеж
<div className="borleft">
<span>2019 </span> <br />
<span>"Тактика врача- стоматолога при заболеваниях слизистой оболочки рта." </span> <br />
 </div>
 Малютин Д.В. Учебный центр СПЕЦИАЛИСТ, г. Воронеж <br />
 <div className="borleft">
<span>2019</span> <br />
<span>"Цифровая фотография в повседневной стоматологической практике". </span> <br />
</div>
Молчанов А.С. 3D-Stom, г. Тамбов <br />
<div className="borleft">
<span>2019</span>
<span>"Компьютерная томография в ежедневной практике врача - стоматолога". </span>
 </div>
 Соловьева И.В., Учебный центр ДЕНТЕКС, г. Москва. <br />
<div className="borleft">
<span>2020</span>
<span>"Теоретические и практические аспекты, принципы работы и особенности использования аппарата VECTOR ( Dull Dental )  в современной пародонтологии". 
</span>
</div>
Приямпольская М.Б., Учебный центр S.T.I. , г. Москва <br />
<span>2020</span> <br />
<span>"Пародонтологический практикум - интенсив" </span><br />
      </div>
        </div>
        </Col>
    
    </Row>
</Container>

        </div>

     <div className="DocObolenkinaW1">
            <div className="doc1">
            <h1 className="animateDoc12">Сертификаты</h1>
            </div> 
</div>
        <Container>
            <Row>
                <Col xl={2}></Col>
                <Col xl={8}>
                <AutoplaySlider
    play={true}
    cancelOnInteraction={false} 
    interval={6000}
    dots={false}
    
  >
    <div data-src={cert1} />
    <div data-src={cert2} />
    <div data-src={cert3} /> 
     <div data-src={cert4} /> 
      <div data-src={cert5} />
      <div data-src={cert6} />
      <div data-src={cert7} />
      <div data-src={cert8} />
      <div data-src={cert9} />
      <div data-src={cert10} />
  </AutoplaySlider>
                </Col>
                <Col xl={2}></Col>
            </Row>
 
  </Container>
    
    </>}
        
</>
    )
}
export default DocObolenkina;