import React from "react";
import {ParallaxBanner, ParallaxProvider, ParallaxBannerLayer, Parallax} from "react-scroll-parallax";
import { animated } from '@react-spring/web'
import {Link} from "react-router-dom";
import {useEffect} from 'react';
import {Row, Col, Container} from "react-grid-system";
import AOS from "aos";
import 'aos/dist/aos.css';
import "./Content.scss";
import video from "./start.mp4";
import {Helmet} from "react-helmet";
import { useMediaQuery } from "react-responsive";
import imag1 from "./1.png";
import imag2 from "./2.png";
import imag3 from "./3.png";
import imag4 from "./4.png";
import imag5 from "./5.png";
import imag6 from "./6.png";
import PhotoAlbum from "react-photo-album";
import photosCont from "./photosCont";
const draw = {
    hidden: {pathLength: 0, opacity: 0},
    visible: (i) => {
        const delay = 1 + i * 0.5;
        return {
            pathLength: 1,
            opacity: 5,
            transition: {
                pathLength: {delay, type: "spring", duration: 1.5, bounce: 0},
                opacity: {delay, duration: 0.01}
            }
        };
    }
};

const Content = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    let background = {
        image: '/nebo.png',
        translateY: [0, 15],
        opacity: [1, 0.3],
        scale: [1.05, 1, 'easeOutCubic'],
        shouldAlwaysCompleteAnimation: true,
    };


    let foreground = {
        image: '/Group.png',
        translateY: [0, 45],
        scale: [1, 1.1, 'easeOutCubic'],
        shouldAlwaysCompleteAnimation: true,
    };

    let gradientOverlay = {
        opacity: [0, 0.9],
        shouldAlwaysCompleteAnimation: true,
        expanded: false,
        children: (
            <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-blue-900"/>
        ),
    };
    let backgroundw8 = {
        image: '/wrap8.png',
        translateY: [0, 15],
        opacity: [1, 0.3],
        scale: [1.05, 1, 'easeOutCubic'],
        shouldAlwaysCompleteAnimation: true,
    };
    const mediaResizeMobile = useMediaQuery({
        query: '(max-width: 1200px)'
    });
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1225px)'
    });
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });


    return (
        <>
        <Helmet>
            <title>Центр Современной Стоматологии</title>
        </Helmet>
        {isDesktopOrLaptop && <>
            <ParallaxProvider>

                <ParallaxBanner
                    layers={[background, foreground, gradientOverlay]}
                    className="aspect-[2/1] bg-gray-900"
                />
            </ParallaxProvider>
            <Container>

                <div className="wrap3">
                    <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
                        <span>ЗА </span>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="400">
                        <span>КАКОЙ </span>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="600">
                        <span>УСЛУГОЙ </span>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="800">
                        <span>ВЫ </span>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="1000">
                        <span>К </span>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="1200">
                        <span>НАМ </span>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="1400">
                        <span>ОБРАЩАЕТЕСЬ? </span>
                    </div>
                </div>
            </Container>
            <Container>
                <div data-aos="fade-up" className="wrap5" data-aos-duration="500" data-aos-delay="500">
                    <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500">
                        <Row>
                            <Col xl={4} lg={12} md={12} className="mt-6 wrap51">
                                <Row>
                                    <Col md={12} lg={12} xl={3} className="centerImg">
                               
                                    <img src="/img/ico/tooth.png" width={50}/>
                                    </Col>
                                    <Col className="centerImg" md={12} lg={12} xl={9}>
                                        <Link to="/gigien">Профилактика</Link>
                                        </Col>
                                        </Row>
                                        </Col>

                            <Col xl={4} lg={12} md={12} className="mt-6 wrap51"><Row><Col md={12} lg={12} xl={3} className="centerImg">
                                <img src="/img/ico/zubdel.png" width={45}/>
                                </Col><Col className="centerImg" md={12} lg={12} xl={9}><Link to="/hirurg">Хирургия</Link></Col></Row></Col>


                            <Col xl={4} lg={12} md={12} className="mt-6 wrap51"><Row><Col md={12} lg={12} xl={3} className="centerImg">
                                <img src="/img/ico/Group1.png" width={50}/>
                                </Col><Col className="centerImg" md={12} lg={12} xl={9}><Link to="/ordont">Ортодонтия</Link>
                                                                                 </Col>
                                                                                 </Row>
                                                                                 </Col>
                        </Row>
                    </div>
                    <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500">
                        <Row>
                            <Col xl={4} lg={12} md={12} className="mt-6 wrap51">
                                <Row>
                                    <Col md={12} lg={12} xl={3} className="centerImg"><img src="/img/ico/Group2.png" width={50} /></Col>
                                    <Col className="centerImg" md={12} lg={12} xl={9}><Link to="/ortoped">Ортопедия</Link></Col>
                                </Row>
                            </Col>
                            <Col xl={4} lg={12} md={12} className="mt-6 wrap51">
                                <Row>
                                    <Col md={12} lg={12} xl={3} className="centerImg"><img src="/img/ico/zub.png" width={50}/></Col>
                                    <Col className="centerImg" md={12} lg={12} xl={9}><Link to="/terap">Терапия</Link></Col>
                                </Row>
                            </Col>

                            <Col xl={4} lg={12} md={12} className="mt-6 wrap51">
                                <Row>
                                    <Col md={12} lg={12} xl={2} className="centerImg"><img src="/img/ico/zubdel.png" width={45}/></Col>
                                    <Col className="centerImg" md={12} lg={12} xl={10}><Link to="/impl">Имплантация зубов </Link></Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>



                </div>

            </Container>


            <div className="wrap6">
  <ParallaxProvider >{/* isDisabled={mediaResizeMobile} */}
    <ParallaxBanner style={{ aspectRatio: '2 / 1' }}>
      <ParallaxBannerLayer image="/img/instrument.jpg" speed={-30} />
      <ParallaxBannerLayer> 
        <div className="absolute inset-0 flex items-center justify-center">
        <div data-aos="fade-up"
            data-aos-duration="500"
            data-aos-delay="500" className="wrap61 text-white font-thin">«Центр Современной Стоматологии» - это место, куда можно обратиться с любыми
            проблемами челюстно-лицевой системы. 
       Наши врачи проведут все необходимые обследования, осуществят плановое лечение,
        быстро окажут помощь в сложной ситуации, тщательно подойдут к осуществлению
         профилактических мероприятий.
       Деятельность центра направлена не только на восстановление жевательной функции зубов,
        но и на обеспечение в полной мере их эстетики.
       Сегодня полный пакет услуг премиального качества можно получить не покидая пределов
        Тамбовской области, обратившись в Центр Современной Стоматологии. 
       Мы располагаем всеми средствами и условиями, чтобы создать идеальную улыбку.</div>
      </div>
    
      </ParallaxBannerLayer>
    </ParallaxBanner>
</ParallaxProvider>

</div>

                <div className="wrap7">
            <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500">
                
                    <Container>
                        <Row className="mt-12">
                            <Col Align="center" className="mb-6"  xl={12}>
                                <h1>НАШИ <span>ПРИНЦИПЫ</span></h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={6} className="items-center flex">
<span className="textCont1"> Во главе всего стоит пациент и его здоровье-
     это главное правило нашего центра. Мы применяем только те материалы и методики лечения, в
     надежности и эффективности которых уверены на 100%.</span>
                            </Col>
                            
                          
                            
                            <Col xl={6} className="items-center flex justify-center">
                                <img src="./img/ico/Group6.png" className="wi71"/>
                            </Col>
                        </Row>

                                

                                <Row className="mt-6">
                            <Col xl={6} className="items-center flex justify-center">

                           

                                <img src="./img/ico/473951.png" className="wi71"/>
                            </Col>
                          
                            
                         
                            <Col xl={6} className="items-center flex">
<span className="textCont1">
По своей эмоциональной составляющей, наша клиника скорее геостеприимный дом,
    чем официальное медицинское учреждение. Отсутствие официоза, доброжелательный,
    вежливый персонал, качественные услуги стоматологической клиники – это лишь малая
    часть того, что мы можем предложить нашим пациентам.</span>
                            </Col>
                       
                            
                            </Row>


                        
                    </Container>
                    </div>
            </div>
<hr />

        

<video
        width={"100%"}
        src={video}
        autoPlay={true}
        controls={false}
        loop={true} 
      />
         
            {/* <ParallaxProvider>
                <Parallax translateY={['50px', '-50px']}>
                             <img src={imag1} alt="Image 1" />
                </Parallax>
                
                <Parallax translateY={['100px', '-100px']} >
         <img src={imag2} alt="Image 2" />
                </Parallax>

                <Parallax translateY={['150px', '-150px']} >
         <img src={imag3} alt="Image 3" />
                </Parallax>

                <Parallax translateY={['100px', '-100px']} >
         <img src={imag4} alt="Image 4" />
                </Parallax>

                <Parallax translateY={['50px', '-50px']} >
         <img src={imag5} alt="Image 5" />
                </Parallax>
         </ParallaxProvider>
             */}<div className="wrap7">
                <Container>
             <Row className="mt-12">
            <Col Align="center" className="mb-6"  xl={12}>
                <h1>НАШ <span>??</span></h1>
            </Col>
        </Row>
        </Container>
        </div>
             <div className="wrapCon9">
               <div data-aos="fade-up" data-aos-duration="700" data-aos-delay="400">
             <img src={imag1} alt="image" />
             </div>
             <div data-aos="fade-up" data-aos-duration="700" data-aos-delay="600">
             <img src={imag2} alt="image" />
             </div>
     
             <div data-aos="fade-up" data-aos-duration="700" data-aos-delay="400">
             <img src={imag3} alt="image" />
             </div>
             </div>
              
             <div className="wrapCon9">
             <div data-aos="fade-up" data-aos-duration="700" data-aos-delay="600">
             <img src={imag4} alt="image" />
             </div>
      
             <div data-aos="fade-up" data-aos-duration="700" data-aos-delay="800">

             <img src={imag5} alt="image" />
             
</div>     
        <div data-aos="fade-up" data-aos-duration="700" data-aos-delay="600">
             <img src={imag6} alt="image" />               
        </div>
</div>
           
             </>}







          {/* mobile */}






          {isTabletOrMobile &&<>
          

            <ParallaxProvider>

<ParallaxBanner
    layers={[background, foreground, gradientOverlay]}
    className="aspect-[2/1] bg-gray-900"
/>
</ParallaxProvider>
<Container>

<div className="wrap3">
    <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
        <span>ЗА </span>
    </div>
    <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="400">
        <span>КАКОЙ </span>
    </div>
    <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="600">
        <span>УСЛУГОЙ </span>
    </div>
    <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="800">
        <span>ВЫ </span>
    </div>
    <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="1000">
        <span>К </span>
    </div>
    <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="1200">
        <span>НАМ </span>
    </div>
    <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="1400">
        <span>ОБРАЩАЕТЕСЬ? </span>
    </div>
</div>
</Container>
<Container>
<div data-aos="fade-up" className="wrap5" data-aos-duration="500" data-aos-delay="500">
   <Row>
            <Col xl={4} lg={6} md={6} sm={6} className="mt-6 wrap51">
                <Row>
                    <Col md={12} lg={12} xl={3} className="centerImg">
               
                    <img src="/img/ico/tooth.png" width={50}/>
                    </Col>
                    <Col className="centerImg" md={12} lg={12} xl={9}>
                        <Link to="/gigien">Профилактика</Link>
                        </Col>
                        </Row>
                        </Col>

            <Col xl={4} lg={6} md={6} sm={6} className="mt-6 wrap51"><Row><Col md={12} lg={12} xl={3} className="centerImg">
                <img src="/img/ico/zubdel.png" width={45}/>
                </Col><Col className="centerImg" md={12} lg={12} xl={9}><Link to="/hirurg">Хирургия</Link></Col></Row></Col>


                <Col xl={4} lg={6} md={6} sm={6} className="mt-6 wrap51"><Row><Col md={12} lg={12} xl={3} className="centerImg">
                <img src="/img/ico/Group1.png" width={50}/>
                </Col><Col className="centerImg" md={12} lg={12} xl={9}><Link to="/ordont">Ортодонтия</Link>
                                                                 </Col>
                                                                 </Row>
                                                                 </Col>
   
  

        <Col xl={4} lg={6} md={6} sm={6} className="mt-6 wrap51">
                <Row>
                    <Col md={12} lg={12} xl={3} className="centerImg"><img src="/img/ico/Group2.png" width={50} /></Col>
                    <Col className="centerImg" md={12} lg={12} xl={9}><Link to="/ortoped">Ортопедия</Link></Col>
                </Row>
            </Col>
            <Col xl={4} lg={6} md={6} sm={6} className="mt-6 wrap51">
                <Row>
                    <Col md={12} lg={12} xl={3} className="centerImg"><img src="/img/ico/zub.png" width={50}/></Col>
                    <Col className="centerImg" md={12} lg={12} xl={9}><Link to="/terap">Терапия</Link></Col>
                </Row>
            </Col>

            <Col xl={4} lg={6} md={6} sm={6} className="mt-6 wrap51">
                <Row>
                    <Col md={12} lg={12} xl={2} className="centerImg"><img src="/img/ico/zubdel.png" width={45}/></Col>
                    <Col className="centerImg" md={12} lg={12} xl={10}><Link to="/impl">Имплантация зубов </Link></Col>
                </Row>
            </Col>
   
   </Row>



</div>

</Container>


<div className="wrap6">
<ParallaxProvider >{/* isDisabled={mediaResizeMobile} */}
<ParallaxBanner style={{ aspectRatio: '2 / 1' }}>
<ParallaxBannerLayer image="/img/instrument.jpg" speed={10} />
<ParallaxBannerLayer> 
<div className="absolute inset-0 flex items-center justify-center">
<div data-aos="fade-up"
data-aos-duration="500"
data-aos-delay="500" className="wrap61 text-white font-thin">
    «Центр Современной Стоматологии» - это место, куда можно обратиться с любыми
     проблемами челюстно-лицевой системы. 
Наши врачи проведут все необходимые обследования, осуществят плановое лечение,
 быстро окажут помощь в сложной ситуации, тщательно подойдут к осуществлению
  профилактических мероприятий.
Деятельность центра направлена не только на восстановление жевательной функции зубов,
 но и на обеспечение в полной мере их эстетики.
Сегодня полный пакет услуг премиального качества можно получить не покидая пределов
 Тамбовской области, обратившись в Центр Современной Стоматологии. 
Мы располагаем всеми средствами и условиями, чтобы создать идеальную улыбку.</div>
</div>

</ParallaxBannerLayer>
</ParallaxBanner>
</ParallaxProvider>

</div>

<div className="wrap7">
<div data-aos="fade-up" data-aos-duration="500" data-aos-delay="500">

    <Container>
        <Row className="mt-12">
            <Col Align="center" className="mb-6"  xl={12}>
                <h1>НАШИ <span>ПРИНЦИПЫ</span></h1>
            </Col>
        </Row>
        <Row>
            <Col xl={6} className="items-center flex">
<span className="textCont1"> Во главе всего стоит пациент и его здоровье-
это главное правило нашего центра. Мы применяем только те материалы и методики лечения, в
надежности и эффективности которых уверены на 100%.</span>
            </Col>
            
            <Col xl={6} className="items-center flex justify-center">
                <img src="./img/ico/Group6.png" className="wi71"/>
            </Col>
        </Row>
        <Container>
               <div className="hrCont"></div>
             </Container>
                <Row>
                <Col xl={6} className="items-center flex">
<span className="textCont1">
По своей эмоциональной составляющей, наша клиника скорее геостеприимный дом,
чем официальное медицинское учреждение. Отсутствие официоза, доброжелательный,
вежливый персонал, качественные услуги стоматологической клиники – это лишь малая
часть того, что мы можем предложить нашим пациентам.</span>
            </Col>
            <Col xl={6} className="items-center flex justify-center">
                <img src="./img/ico/473951.png" className="wi71"/>
            </Col>
            </Row> 
    </Container>
    </div>
</div>




            
 

<video
        src={video}
        autoPlay={true}
        controls={false}
        loop={true} 
      />
          

          </>}

        </>

    )
}
export default Content