import React from "react";
import { Container, Row, Col } from "react-grid-system";
import "./Ordont.scss";
import 'swiper/css/autoplay';
import { Helmet } from "react-helmet";
const Ordont = () =>{
    return(
        <>
		<Helmet>
			<title>Ортодонтия</title>
		</Helmet>
        <div className="ord1">
        <h1>Наши услуги</h1>
        <span className="animateСharcterOR">Ортодонтия</span>
     
        </div>

            <Container>
		
                <Row>
                    <Col lg={4}>
   <div className="ord2">
            <img src="/img/doc2.png" width="400px" alt="imag"/>
			</div>
                    </Col>
                    <Col lg={8}>
                        <div className="ord22">
                      <p>  Привлекательная и сияющая улыбка – это не только признак здоровья, но и ваша визитная карточка,
                         которая открывает двери к успеху и уверенности в себе. В нашей стоматологической клинике мы понимаем 
                         важность идеального прикуса и красивой улыбки. Поэтому наши высококвалифицированные ортодонты предлагают современные
                          и эффективные решения для исправления прикуса и выравнивания зубов.
                      </p>
<p>Независимо от вашего возраста или степени сложности проблемы, мы имеем опыт работы с самыми сложными случаями и готовы
     разработать индивидуальный план лечения, который идеально подойдет вашим потребностям и образу жизни. Благодаря использованию 
     новейших технологий, таких как невидимые брекеты и лингвальные брекеты, мы можем обеспечить максимально комфортное и эстетичное лечение.
</p>
<p>Наша команда ортодонтов не только поможет вам обрести идеальную улыбку, но и обеспечит полную поддержку на каждом этапе вашего путешествия.
     Мы стремимся создать атмосферу, в которой вы будете чувствовать себя комфортно и уверенно, зная, что ваше здоровье и красота находятся в надежных руках.
</p>
<p>Инвестируйте в свою улыбку сегодня и позвольте нам помочь вам раскрыть ее потенциал.
     Запишитесь на консультацию прямо сейчас и сделайте первый шаг к идеальной улыбке, которая будет вашим ключом
      к успеху и уверенности в себе!
</p>
</div>
                    </Col>
					</Row>
					
            </Container>
		
						<Container>
                    <table className="tbgig">
	<tbody>
	
		<tr>	
		
			<td colSpan={2} align="center">	<div className="td2" >Прейскурант цен ООО «Центр Современной Стоматологии» <br /> на ортодонтические услуги </div></td>
		
		</tr>
		
		
		<tr>
			<td><div >Консультация врача - специалиста</div></td>
			<td><div className="td2" >1 000</div></td>
		</tr>
		<tr>
			<td><div >Диагностика клинического случая с составлением плана лечения (фотопротокол + диагностические модели)</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
		<tr>
			<td><div >Установка Twin-block</div></td>
			<td><div className="td2" >30 000</div></td>
		</tr>
		<tr>
			<td><div >Установка аппарата Marco Ross</div></td>
			<td><div className="td2" >30 000</div></td>
		</tr>
		<tr>
			<td><div >Установка лигатурной брекет-системы на одну челюсть</div></td>
			<td><div className="td2" >40 000</div></td>
		</tr>
		<tr>
			<td><div >Коррекция брекет-системы Н4</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
        {/* <tr>
			<td><div >Коррекция лигатурной брекет-системы</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
        <tr>
			<td><div >Лечение на LM активаторе</div></td>
			<td><div className="td2" >15 000</div></td>
		</tr>
        <tr>
			<td><div >Повторный прием врача-ортодонта</div></td>
			<td><div className="td2" >800</div></td>
		</tr>
        <tr>
			<td><div >Полировка зуба после снятия брекета </div></td>
			<td><div className="td2" >250</div></td>
		</tr>
        <tr>
			<td><div >Снятие брекета с одного зуба</div></td>
			<td><div className="td2" >250</div></td>
		</tr>
        <tr>
			<td><div >Установка брекет-системы (от 1 до 3 зубов)</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
        <tr>
			<td><div >Установка брекет-системы (от 4 до 6 зубов)</div></td>
			<td><div className="td2" >10 000</div></td>
		</tr>
        <tr>
			<td><div >Установка брекет-системы (от 7 до 10 зубов)</div></td>
			<td><div className="td2" >15 000</div></td>
		</tr>
        <tr>
			<td><div >Установка дуги на 1 челюсть</div></td>
			<td><div className="td2" >1 500</div></td>
		</tr>
        <tr>
			<td><div >Ремонт ретейнера</div></td>
			<td><div className="td2" >2 000</div></td>
		</tr>
        <tr>
			<td><div >Ретенционная каппа одна челюсть</div></td>
			<td><div className="td2" >3 000</div></td>
		</tr>
        <tr>
			<td><div >Ретенционный аппарат </div></td>
			<td><div className="td2" >15 000</div></td>
		</tr>
        <tr>
			<td><div >Сьемный аппарат (пластинка) </div></td>
			<td><div className="td2" >15 000</div></td>
		</tr>
        <tr>
			<td><div >Фиксация ретейнера на 4 зуба </div></td>
			<td><div className="td2" >3 000</div></td>
		</tr>
        <tr>
			<td><div >Фиксация ретейнера на 6 зубов</div></td>
			<td><div className="td2" >4 500</div></td>
		</tr>
        <tr>
			<td><div >Фиксация ретейнера на 8 зубов</div></td>
			<td><div className="td2" >6 000</div></td>
		</tr>
        <tr>
			<td><div >Замена элементов брекет-системы</div></td>
			<td><div className="td2" >1 000</div></td>
		</tr>
        <tr>
			<td><div >Осмотр пациента другой клиники, коррекция с использованием материала нашей клиники</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
        <tr>
			<td><div >Функциональные аппараты</div></td>
			<td><div className="td2" >20 000</div></td>
		</tr>
        <tr>
			<td><div >Снятие керамических брекетов</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
        <tr>
			<td><div >Снятие металлических брекетов</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>


	
		<tr>	
		
			<td colSpan={2} align="center">	<div className="td2" >Ортодонтия Н4</div></td>
		
		</tr>



        <tr>
			<td><div >Диагностика клинического случая с составлением плана лечения для частичной брекет-системы (фотопротокол + диагностические модели)</div></td>
			<td><div className="td2" >3 000</div></td>
		</tr>
        <tr>
			<td><div >Диагностика клинического случая с составлением плана лечения (фотопротокол + диагностические модели)</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
        <tr>
			<td><div >Замена эластичной лигатуры на один зуб</div></td>
			<td><div className="td2" >100</div></td>
		</tr>
        <tr>
			<td><div >Кольцодержатель места </div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
        <tr>
			<td><div >Коррекция Twin-block</div></td>
			<td><div className="td2" >1 000</div></td>
		</tr>
        <tr>
			<td><div >Коррекция брекет-системы Н4</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
        <tr>
			<td><div >Коррекция частичной брекет-системы 2х4</div></td>
			<td><div className="td2" >4 000</div></td>
		</tr>
        <tr>
			<td><div >Первичный прием врача - ортодонта</div></td>
			<td><div className="td2" >1 000</div></td>
		</tr>
        <tr>
			<td><div >Повторный прием врача - ортодонта</div></td>
			<td><div className="td2" >800</div></td>
		</tr>
        <tr>
			<td><div >Снятие альгинатного слепка на одну челюсть</div></td>
			<td><div className="td2" >1 000</div></td>
		</tr>
        <tr>
			<td><div >Снятие аппарата Marco Rossa</div></td>
			<td><div className="td2" >3 000</div></td>
		</tr>
        <tr>
			<td><div >Установка Twin-block</div></td>
			<td><div className="td2" >30 000</div></td>
		</tr>
        <tr>
			<td><div >Установка аппарата Marco Rossa</div></td>
			<td><div className="td2" >30 000</div></td>
		</tr>
        <tr>
			<td><div >Установка лигатурной брекет-системы на одну челюсть.</div></td>
			<td><div className="td2" >40 000</div></td>
		</tr>
        <tr>
			<td><div >Установка окклюзионной накладки</div></td>
			<td><div className="td2" >700</div></td>
		</tr>
        <tr>
			<td><div >Установка ортодонтического минивинта Bioray</div></td>
			<td><div className="td2" >12 000</div></td>
		</tr>
        <tr>
			<td><div >Установка ортодонтического минивинта Ortolight</div></td>
			<td><div className="td2" >12 000</div></td>
		</tr>
        <tr>
			<td><div >Установка ретейнера Ortolight одна челюсть</div></td>
			<td><div className="td2" >3 000</div></td>
		</tr>
        <tr>
			<td><div >Установка самолигулирующей брекет-системы на одну челюсть.</div></td>
			<td><div className="td2" >50 000</div></td>
		</tr>
        <tr>
			<td><div >Установка съемного пластиночного аппарата на одну челюсть</div></td>
			<td><div className="td2" >15 000</div></td>
		</tr>
        <tr>
			<td><div >Установка частичной брекет-системы 2х4</div></td>
			<td><div className="td2" >15 000</div></td>
		</tr>
        <tr>
			<td><div >Установка эластических тяг</div></td>
			<td><div className="td2" >200</div></td>
		</tr>         */}

	
		

	</tbody>
</table>
</Container>              
               
        
        </>
    )
}
export default Ordont