import "./Nas.scss";
import  "./ihover.css";
import { Container, Row, Col } from "react-grid-system";
import { Helmet } from "react-helmet";
import { useState } from "react";
import frame1 from "./Frame11.png";
import frame2 from "./Frame12.png";
import frame3 from "./Frame13.png";
import frame4 from "./Frame14.png";
import frame5 from "./Frame15.png";
import frame6 from "./Frame16.png";
import frame7 from './Group17.png';
import { Link } from "react-router-dom";
const ImageComponent = ({ imageSrc, textOverlay }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="image-container">
      <img
        src={imageSrc}
        alt="Your Image"
        className={`image ${isHovered ? "hovered" : ""}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      {isHovered && (
        <div className="text-overlay" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          {textOverlay}
        </div>
      )}
    </div>
  );
};

const Nas = () => {
  return (
    <>
      <Helmet>
        <title>Наши врачи</title>
      </Helmet>
      <div className="uw1">
        <h1 className="animateNas">Наши врачи</h1>
      </div>
      <div className="nas">
        <Container>
          <Row className="NasImg1">
            
            <Col sm={12} md={12} lg={12} xl={4} xxl={4} xxxl={4} className="NasImg">
              {/* <ImageComponent imageSrc={frame1} textOverlay="Хабибулин Дмитрий Марсович (стоматолог-терапевт, стоматолог-ортопед, стоматолог- хирург)" />
            */}
                 <div className="ih-item circle effect3 left_to_right"><Link to="/DocHabibulin">
        <div className="img"><img src={frame1} alt="img"/></div>
        <div className="info">
          <h3>Хабибулин <br /> Дмитрий <br /> Марсович</h3> <br /> <br />
          <p>стоматолог-терапевт,<br /> стоматолог-ортопед,<br /> стоматолог- хирург</p>
        </div></Link></div>
            </Col> 
            <Col sm={12} md={12} lg={12} xl={4} xxl={4}  xxxl={4} className="NasImg">
               <div className="ih-item circle effect3 left_to_right"><Link to="/DocDrushkina">
        <div className="img"><img src={frame6} alt="img"/></div>
        <div className="info">
          <h3>Дружкина <br /> Виктория <br /> Николаевна</h3> <br /> <br />
          <p>стоматолог-терапевт,<br /> стоматолог-эндодонтист</p>
        </div></Link></div>
            </Col>


            <Col sm={12} md={12} lg={12} xl={4} xxl={4}  xxxl={4}  className="NasImg">
              <div className="ih-item circle effect3 left_to_right"><Link to="/DocGukasyan">
        <div className="img"><img src={frame2} alt="img"/></div>
        <div className="info">
          <h3>Гукасян<br /> Вачик<br />Артушевич</h3> <br /><br />
          <p>хирург-имплантолог</p>
        </div></Link></div>
            </Col>

            <Col sm={12} md={12} lg={12} xl={4} xxl={4}  xxxl={4} className="NasImg">
               <div className="ih-item circle effect3 left_to_right"><Link to="/DocKonyahina">
        <div className="img"><img src={frame4} alt="img"/></div>
        <div className="info">
          <h3>Коняхина <br /> Виктория <br /> Евгеньевна</h3> <br /><br />
          <p>стоматолог-терапевт,<br /> стоматолог-ортопед,<br /> стоматолог- хирург</p>
        </div></Link></div>
            </Col>
           
            <Col sm={12} md={12} lg={12} xl={4} xxl={4}  xxxl={4} className="NasImg">
               <div className="ih-item circle effect3 left_to_right"><Link to="/DocRegina">
        <div className="img"><img src={frame5} alt="img"/></div>
        <div className="info">
          <h3>Исмагилова <br /> Регина <br /> Эдуардовна</h3> <br /><br />
          <p>стоматолог-терапевт</p>
        </div></Link></div>
            </Col>
            <Col sm={12} md={12} lg={12} xl={4} xxl={4}  xxxl={4}  className="NasImg">
              <div className="ih-item circle effect3 left_to_right"><Link to="/DocObolenkina">
        <div className="img"><img src={frame3} alt="img"/></div>
        <div className="info">
          <h3>Оболенкина <br /> Ирина <br /> Алексеевна</h3> <br /><br />
          <p>стоматолог- гигиенист</p>
        </div></Link></div>
            </Col>
            <Col sm={12} md={12} lg={12} xl={4} xxl={4}  xxxl={4} className="NasImg"></Col>
             <Col sm={12} md={12} lg={12} xl={4} xxl={4}  xxxl={4} className="NasImg">
              <div className="ih-item circle effect3 left_to_right"><Link to="/DocKarolina">
        <div className="img"><img src={frame7} alt="img"/></div>
        <div className="info">
          <h3>Селезнева <br /> Каролина <br /> Игоревна</h3> <br /><br />
          <p>врач-ортодонт</p>
        </div></Link></div>
            </Col>
            <Col sm={12} md={12} lg={12} xl={4} xxl={4}  xxxl={4} className="NasImg"></Col>
         
            
            
            
                 
       </Row>
        </Container>

      </div>
    
 

    </>
  );
};

export default Nas;