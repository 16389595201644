import React from "react";
import { Row, Container, Col } from "react-grid-system";
import "./DocKonyahina.scss";
import AOS from "aos";
import 'aos/dist/aos.css';
import doc from "./doc.png";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import { useMediaQuery } from 'react-responsive';
import photos from "./photos";
import PhotoAlbum from "react-photo-album";
const AutoplaySlider = withAutoplay(AwesomeSlider);

const DocKonyahina = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1225px)'
    });
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    useEffect(() => {
        AOS.init();
    }, []);
    return(
        <>
        <Helmet>
        <title>Коняхина Виктория Евгеньевна</title>
    </Helmet>
    {isDesktopOrLaptop && <>
        <div className="DocKonyahinaW1">
            <div className="doc1">
            <h1 className="animateDoc1">Коняхина Виктория Евгеньевна</h1>
            </div>
<Container>
    <Row>
        <Col xxxl={5} xxl={5} xl={5}>
        <div className="absolute inset-0 flex items-center justify-center " data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
      <div className="DocKonyahinaW2"> 
      {/* text */}
      <b>Рязанский государственный медицинский университет им. акад. И.П. Павлова</b> <br />
      <div className="borleft">
<span>2018</span> <br />
Стоматология  <br />
<span>Высшее образование</span> <br />
</div>
<br />
<div className="borleft">
<span>2020</span> <br />
Стоматология хирургическая <br />
<span>Ординатура</span> <br />
</div>
<div className="text-center">
Повышение квалификации
</div>
 

<span>2019</span> <br />
<span>«Базовый курс по имплантации»</span> <br />
 
2020
«Пародонтология», курс Дробышева

      </div>
        </div>
        </Col>
        <Col xxxl={7} xxl={7} xl={7}>
        <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
            <img src={doc}/>
            </div>
        </Col>
    </Row>
</Container>

        </div>

     <div className="DocKonyahinaW1">
            <div className="doc1">
            <h1 className="animateDoc12">Сертификаты</h1>
            </div> 
</div>
        <Container>
        <PhotoAlbum layout="columns" photos={photos} />
 
  </Container>
  </>}





  {isTabletOrMobile &&<>
    <div className="DocKonyahinaW1">
            <div className="doc1">
            <h1 className="animateDoc1">Коняхина Виктория Евгеньевна</h1>
            </div>
<Container>
    <Row>
<Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12}>
        <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
            <img src={doc}/>
            </div>
        </Col>
        </Row>
    <Row>
    <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12}>
        <div className="flex items-center justify-center " data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
      <div className="DocKonyahinaW2"> 
      {/* text */}
      <b>Рязанский государственный медицинский университет им. акад. И.П. Павлова</b> <br />
      <div className="borleft">
<span>2018</span> <br />
Стоматология  <br />
<span>Высшее образование</span> <br />
</div>
<br />
<div className="borleft">
<span>2020</span> <br />
Стоматология хирургическая <br />
<span>Ординатура</span> <br />
</div>
<div className="text-center">
Повышение квалификации
</div>
 

<span>2019</span> <br />
<span>«Базовый курс по имплантации»</span> <br />
 
2020
«Пародонтология», курс Дробышева

      </div>
        </div>
        </Col>
      
    </Row>
</Container>

        </div>

     <div className="DocKonyahinaW1">
            <div className="doc1">
            <h1 className="animateDoc12">Сертификаты</h1>
            </div> 
</div>
        <Container>
        <PhotoAlbum layout="columns" photos={photos} />
 
  </Container>
  </>}
</>
    )
}
export default DocKonyahina;