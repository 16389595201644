import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import "./Menu.scss"
import AOS from "aos";
import Rout from '../Rout/Rout';
import { pushRotate as Burg } from 'react-burger-menu';
import { useMediaQuery } from 'react-responsive'

const Menu = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1225px)'
    });

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    useEffect(() => {
        AOS.init();
    }, []);

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    const handleScroll = () => {
        const currentScrollPos = window.scrollY;

        if (currentScrollPos > prevScrollPos) {
            setVisible(false)
        } else {
            setVisible(true)
        }

        setPrevScrollPos(currentScrollPos)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll)
    });

    const handleMenuClose = () => {
        setIsOpen(false);
    };

    return (
        <>
            {isTabletOrMobile &&
                <Burg
                    isOpen={isOpen}
                    onStateChange={({ isOpen }) => setIsOpen(isOpen)}
                    right
                    width={"100%"}
                    styles={burgerStyles}
                    onClose={handleMenuClose}
                >
                    <Link to="/" onClick={handleMenuClose}>Главная</Link>
                    <Link to="/uslugi" onClick={handleMenuClose}>Услуги</Link>
                    <Link to="/nas" onClick={handleMenuClose}>Наши врачи</Link>
                    <Link to="/otziv" onClick={handleMenuClose}>Отзывы</Link>
                    <Link to="/konkt" onClick={handleMenuClose}>Контакты</Link>
                </Burg>
            }
            {isDesktopOrLaptop &&
                <div id="Menu" >
                    <Link to="/">Главная</Link>
                    <Link to="/uslugi">Услуги</Link>
                    <Link to="/nas">Наши врачи</Link>
                    <Link to="/otziv">Отзывы</Link>
                    <Link to="/konkt">Контакты</Link>
                </div>
            }
            <Rout />
        </>
    )
}

const burgerStyles = {
    bmBurgerButton: {
        position: 'absolute',
        width: '49px',
        height: '29px',
        right: '26px',
        top: '20px',
    },
    bmBurgerBars: {
        background: '#fff',
    },
    bmBurgerBarsHover: {
        background: '#a90000',
    },
    bmCrossButton: {
        height: '24px',
        width: '24px',
    },
    bmCross: {
        background: '#bdc3c7',
    },
    bmMenuWrap: {
        position: 'fixed',
        height: '100%',
        top: '0px'
    },
    bmMenu: {
        background: '#373a47',
        padding: '2.5em 1.5em 0',
        fontSize: '1.15em',
    },
    bmMorphShape: {
        fill: '#373a47',
    },
    bmItemList: {
        display: 'flex',
        flexDirection: 'column',
        color: '#b8b7ad',
        padding: '0.8em',
    },
    bmItem: {
        display: 'inline-block',
        textAlign: 'center',
        borderBottom: '1px solid white',
        width: '100%',
        paddingTop: '20px',
        paddingBottom: '20px',
        marginTop: '5px',
        marginBottom: '5px',
    },
    bmOverlay: {
        background: 'rgba(0, 0, 0, 0.3)',
        position: 'fixed',
        top: '0px',
    }
};

export default Menu
