
const photos = [ 
    {
      src: "/img/DocKonyahina/cert/2.jpg",
      width: 3,
      height: 4
    },
       {
      src: "/img/DocKonyahina/cert/3.jpg",
      width: 5,
      height: 4
    },
    
       {
      src: "/img/DocKonyahina/cert/5.jpg",
      width: 3,
      height: 4
    },  
         {
      src: "/img/DocKonyahina/cert/6.jpg",
      width: 4,
      height: 3
    },       {
      src: "/img/DocKonyahina/cert/7.jpg",
      width: 4,
      height: 3
    },
    
  ];
  export default photos;
  