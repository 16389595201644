import React from "react";
import Nav from "../Nav/Nav";

const Err = () => {
    return(
    <>
    <Nav/>
       <h1> Данной страницы не существет</h1>
   </>
    )
}
export default Err