const photosLab = [
    // {
    //   src: "/img/Uslug/v2/laboratory/1.jpg",
    //      width: 5,
    //   height: 4
    // },  
    {
      src: "/img/Uslug/v2/laboratory/17.jpg",
         width: 5,
      height: 4
    }, 
    {
      src: "/img/Uslug/v2/laboratory/2.jpg",
         width: 5,
      height: 4
    }, 
      {
      src: "/img/Uslug/v2/laboratory/3.jpg",
         width: 5,
      height: 4
    }, 
    {
      src: "/img/Uslug/v2/laboratory/16.jpg",
         width: 5,
      height: 4
    }, 
    {
      src: "/img/Uslug/v2/laboratory/8.jpg",
         width: 5,
      height: 4
    }, 
   //    {
   //    src: "/img/Uslug/v2/laboratory/4.jpg",
   //       width: 5,
   //    height: 4
   //  },  
    //  {
    //   src: "/img/Uslug/v2/laboratory/5.jpg",
    //      width: 5,
    //   height: 4
    // },
    //   {
    //   src: "/img/Uslug/v2/laboratory/6.jpg",
    //      width: 5,
    //   height: 4
    // },   
    
   //   {
   //    src: "/img/Uslug/v2/laboratory/9.jpg",
   //       width: 5,
   //    height: 4
   //  }, 
   //   {
   //    src: "/img/Uslug/v2/laboratory/10.jpg",
   //       width: 5,
   //    height: 4
   //  }, 
    //  {
    //   src: "/img/Uslug/v2/laboratory/11.jpg",
    //      width: 5,
    //   height: 4
    // },  {
    //   src: "/img/Uslug/v2/laboratory/12.jpg",
    //      width: 5,
    //   height: 4
    // },  {
    //   src: "/img/Uslug/v2/laboratory/13.jpg",
    //      width: 5,
    //   height: 4
    // },  {
    //   src: "/img/Uslug/v2/laboratory/14.jpg",
    //      width: 5,
    //   height: 4
    // }, 
   //   {
   //    src: "/img/Uslug/v2/laboratory/15.jpg",
   //       width: 5,
   //    height: 4
   //  }, 
    //   {
    //   src: "/img/Uslug/v2/laboratory/18.jpg",
    //      width: 5,
    //   height: 4
    // }, 
   //   {
   //    src: "/img/Uslug/v2/laboratory/19.jpg",
   //       width: 5,
   //    height: 4
   //  }, 
    //  {
    //   src: "/img/Uslug/v2/laboratory/20.jpg",
    //      width: 5,
    //   height: 4
    // }, 
   //   {
   //    src: "/img/Uslug/v2/laboratory/21.jpg",
   //       width: 5,
   //    height: 4
   //  }, 
    //  {
    //   src: "/img/Uslug/v2/laboratory/22.jpg",
    //      width: 5,
    //   height: 4
    // }, 
];
export default photosLab;