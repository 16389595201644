import React from "react";
import { Row, Container, Col } from "react-grid-system";
import "./DocRegina.scss";
import AOS from "aos";
import 'aos/dist/aos.css';
import doc from "./doc.png";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import { useMediaQuery } from 'react-responsive';
import PhotoAlbum from "react-photo-album";
import photosReg from "./photosReg";
// import cert1 from "./cert1.jpg";
// import cert2 from "./cert2.jpg";
// import cert3 from "./cert3.jpg";
// import cert4 from "./cert4.jpg";
// import cert5 from "./cert5.JPG";
// import cert6 from "./cert6.JPG";
// import cert7 from "./cert7.JPG";
// import cert8 from "./cert8.JPG";
const AutoplaySlider = withAutoplay(AwesomeSlider);

const DocRegina = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1225px)'
    });
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    useEffect(() => {
        AOS.init();
    }, []);
    return(
        <>
        <Helmet>
        <title>Исмагилова Регина Эдуардовна</title>
    </Helmet>
    {isDesktopOrLaptop && <>
        <div className="DocReginaW1">
            <div className="doc1">
            <h1 className="animateDoc1">Исмагилова Регина Эдуардовна</h1>
            </div>
<Container>
    <Row>
        <Col xxxl={5} xxl={5} xl={5}>
        <div className="absolute inset-0 flex items-center justify-center " data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
      <div className="DocReginaW2"> 
      {/* text */}
     <b> "Тамбовский государственный университет имени Г.Р. Державина"</b> <br />
<div className="borleft"> "31.05.03 Стоматология" <br />
<span>2020г</span> <br />
<span>Высшее образование</span> <br />
</div>
"Северо-Западный государственный медицинский университет имени И.И. Мечникова" г. Санкт-Петербург <br />
<div className="borleft"> "Стоматология общей практики.Ординатура" <br />
<span>2023г</span>
</div>
АНО ДПО "АКАДЕМИЯ ПРОФЕССИОНАЛЬНЫХ СТАНДАРТОВ" г. Лыткарино <br />
<div className="borleft"> "СТОМАТОЛОГИЯ ОРТОПЕДИЧЕСКАЯ" <br />
<span>2023г</span>
</div>
АНО ДПО " АКАДЕМИЯ ПРОФЕССИОНАЛЬНЫХ СТАНДАРТОВ" г. Лыткарино <br />
<div className="borleft">"СТОМАТОЛОГИЯ ДЕТСКАЯ" <br />
<span>2024г</span>
</div>
      </div>
        </div>
        </Col>
        <Col xxxl={7} xxl={7} xl={7}>
        <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
            <img src={doc}/>
            </div>
        </Col>
    </Row>
</Container>

        </div>

     <div className="DocReginaW1">
            <div className="doc1">
            <h1 className="animateDoc12">Сертификаты</h1>
            </div> 
</div>

            <Container>
  <PhotoAlbum layout="columns" photos={photosReg} />;
  </Container>

  </>}





  {isTabletOrMobile &&<>
    <div className="DocReginaW1">
            <div className="doc1">
            <h1 className="animateDoc1">Исмагилова Регина Эдуардовна</h1>
            </div>
<Container>
    <Row>
<Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12}>
        <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
            <img src={doc}/>
            </div>
        </Col>
        </Row>
    <Row>
    <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12}>
        <div className="flex items-center justify-center " data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
      <div className="DocReginaW2"> 
      {/* text */}
      "Тамбовский государственный университет имени Г.Р. Державина" <br />
<div className="borleft"> "31.05.03 Стоматология" <br />
<span>2020г</span> <br />
<span>Высшее образование</span> <br />
</div>
"Северо-Западный государственный медицинский университет имени И.И. Мечникова" г. Санкт-Петербург <br />
<div className="borleft"> "Стоматология общей практики.Ординатура" <br />
<span>2023г</span>
</div>
АНО ДПО "АКАДЕМИЯ ПРОФЕССИОНАЛЬНЫХ СТАНДАРТОВ" г. Лыткарино <br />
<div className="borleft"> "СТОМАТОЛОГИЯ ОРТОПЕДИЧЕСКАЯ" <br />
<span>2023г</span>
</div>
АНО ДПО " АКАДЕМИЯ ПРОФЕССИОНАЛЬНЫХ СТАНДАРТОВ" г. Лыткарино <br />
<div className="borleft">"СТОМАТОЛОГИЯ ДЕТСКАЯ" <br />
<span>2024г</span>
</div>
      </div>
        </div>
        </Col>
      
    </Row>
</Container>

        </div>

     <div className="DocReginaW1">
            <div className="doc1">
            <h1 className="animateDoc12">Сертификаты</h1>
            </div> 
</div>
      
 

  <Container>
  <PhotoAlbum layout="columns" photos={photosReg} />;
  </Container>
  </>}
</>
    )
}
export default DocRegina;