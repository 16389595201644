import React from "react";
import { Container, Row, Col } from "react-grid-system";
import "./Gigien.scss";
import 'swiper/css/autoplay';
import { Helmet } from "react-helmet";
import { useMediaQuery } from 'react-responsive';

const Gigien = () =>{
	const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1225px)'
    });
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    return(
        <>
		<Helmet>
            <title>Профессиональная гигиена и отбеливание</title>
        </Helmet>
		{isDesktopOrLaptop && <>
        <div className="gig1">
        <h1>Наши услуги</h1>
        <span className="animateСharcterG">Профессиональная гигиена и отбеливание</span>
     
        </div>

            <Container>
		
                <Row>
                    <Col lg={4}>
   <div className="gig2">
            <img src="/img/gigien.png"  alt=""/>
			</div>
                    </Col>
                    <Col lg={8}>
                        <div className="gig22">
			<p>Профессиональная гигиена полости рта на аппарате AIR FLOW PROPHYLAXIS MASTER:
			 это инновационный аппарат. Главное его преимущество-это 100% устранение зубных
			  отложений с поверхности зубов. Процедура проводится по специальному швейцарскому
			   протоколу GBT. Благодаря системе регулировки температуры воды, обеспечивается 
			   комфорт даже для пациентов с повышенной чувствительностью эмали.
			Мелкоабразивный порошок позволяет чистить и полировать во время одной процедуры
			</p>
</div>
                    </Col>
					</Row>
					
            </Container>
		<br />
						<Container>
                    <table className="tbgig">
	<tbody>	
		<tr>
			<td colSpan={2} align="center"><div className="td2" >Профилактика</div></td>
			
		</tr>
	
		<tr>
			<td><div className="td1">Профессиональная гигиена полости рта на аппарате PROPHYLAXIS MASTER</div></td>
			<td><div className="td2" >7 000</div></td>
		</tr>
		
		
		<tr>
			<td><div className="td1">Профессиональная гигиена полости рта в молочном прикусе</div> </td>
			<td><div className="td2" >3000</div></td>
		</tr>
		
		
		<tr>
			<td><div className="td1">​​​​​​​Профессиональная гигиена полости рта в сменном прикусе</div></td>
			<td><div className="td2" >4000</div></td>
		</tr>
		
		
		<tr>
			<td><div className="td1">​​​​​​​Реминерализующая терапия</div></td>
			<td><div className="td2" data-aos-offset="10" >4000</div></td>
		</tr>
{/* 			
		<tr>
			<td><div >Профессиональная гигиена полости рта в сменном прикусе</div></td>
			<td><div className="td2" >4 000</div></td>
		</tr>

		<tr>
			<td><div >Реминерализующая терапия</div></td>
			<td><div className="td2" >4 000</div></td>
		</tr>
	
		<tr>
			<td><div >Чистка зубов ультразвуковым методом 1 челюсть</div></td>
			<td><div className="td2" >2 000</div></td>
		</tr>
	
		
		<tr>
			<td><div >Чистка и полировка зубов щеткой и пастой 2 челюсти</div></td>
			<td><div className="td2" >2 000</div></td>
		</tr>
		
		
		<tr>
			<td><div >Чистка зубов аппаратом AIR-Flow 1  челюсть</div></td>
			<td><div className="td2" >2 000</div></td>
		</tr>
		
		
		<tr>
			<td><div >Профессиональная гигиена полости рта на аппарате PROPHYLAXIS MASTER</div></td>
			<td><div className="td2" >7 000</div></td>
		</tr>
		
		
		<tr>
			<td><div >Профессиональная гигиена полости рта на аппарате PROPHYLAXIS MASTER (повторный профилактический прием через 6 месяцев)</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
		
		
		<tr>
			<td><div >Профессиональная комплексная чистка полости рта (УЗ -скейлинг, Air-Flow, полировка)</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
		
		
		<tr>
			<td colSpan={2} align="center"><div className="td2" >Сервис</div></td>
			
		</tr>
		
		
		<tr>
			<td><div >Замена клинических винтов на постоянном протезе (3 винта)</div></td>
			<td><div className="td2" >10 000</div></td>
		</tr>
		
		
		<tr>
			<td><div >Замена клинических винтов на постоянном протезе (4 винта)</div></td>
			<td><div className="td2" >12 000</div></td>
		</tr>
		
		
		<tr>
			<td><div >Замена клинических винтов на постоянном протезе (6 винта)</div></td>
			<td><div className="td2" >20 000</div></td>
		</tr>
		
		
		<tr>
			<td><div >Сервисное обслуживание условно-съемного протеза</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
		 */}
	</tbody>
</table>
</Container>              
               </>}


{/* mobile */}



			   {isTabletOrMobile &&<>
				<div className="gig1">
        <h1>Наши услуги</h1>
        <span className="animateСharcterG">Профессиональная гигиена и Отбеливание</span>
     
        </div>

            <Container>
		
                <Row>
                    <Col lg={4}>
   <div className="gig2">
            <img src="/img/gigien.png"  alt=""/>
			</div>
                    </Col>
                    <Col lg={8}>
                        <div className="gig22">
<p>Отбеливание и профессиональная гигиена
Хотите иметь белоснежную улыбку и здоровые зубы? Тогда приходите в нашу 
    стоматологическую клинику, где мы предлагаем профессиональное отбеливание 
    и гигиену для вашей улыбки!

Отбеливающие процедуры – это безопасный и эффективный способ осветлить вашу эмаль 
на несколько оттенков за один визит. Наша команда квалифицированных стоматологов 
применяет инновационные методы и современное оборудование, чтобы достичь идеального 
результата. В отличие от многочисленных домашних средств, которые обещают быстрые результаты,
 но часто разочаровывают, наше отбеливание проводится под контролем специалиста и отличается 
 максимальной безопасностью и эффективностью.

Наши опытные гигиенисты обеспечат профессиональную чистку вашей полости рта.
 Мы очистим зубы от налета, камней и пигментации, которые накопились со временем.
  Регулярные профессиональные гигиенические процедуры помогут вам избежать проблем, 
  связанных с зубной гнилью, пародонтозом и другими заболеваниями полости рта. Восстановите
   свежесть дыхания и полный комфорт во время еды с помощью наших экспертов в области стоматологии.
  
   Приходите в нашу стоматологическую клинику, чтобы испытать безупречное отбеливание 
и профессиональную гигиену! Мы гарантируем комфортное обслуживание и индивидуальный подход 
к каждому пациенту. Мы заботимся о вашей улыбке и здоровье, поэтому выбирайте надежных 
профессионалов, чтобы достичь идеальных результатов. Обратитесь к нам сегодня и получите 
безупречную улыбку, о которой вы всегда мечтали!</p>
</div>
                    </Col>
					</Row>
					
            </Container>
		
						<Container>
                    <table className="tbgig1">
	<tbody>
	
	<tr>
			<td colSpan={2} align="center"><div className="td2" >Профилактика</div></td>
			
		</tr>
	
		<tr>
			<td><div className="td1">Профессиональная гигиена полости рта на аппарате PROPHYLAXIS MASTER</div></td>
			<td><div className="td2" >7 000</div></td>
		</tr>
		
		
		<tr>
			<td><div className="td1">Профессиональная гигиена полости рта в молочном прикусе</div> </td>
			<td><div className="td2" >3000</div></td>
		</tr>
		
		
		<tr>
			<td><div className="td1">​​​​​​​Профессиональная гигиена полости рта в сменном прикусе</div></td>
			<td><div className="td2" >4000</div></td>
		</tr>
		
		
		<tr>
			<td><div className="td1">​​​​​​​Реминерализующая терапия</div></td>
			<td><div className="td2" data-aos-offset="10" >4000</div></td>
		</tr>
	</tbody>
</table>
</Container>    
			   </>}
        
        </>
    )
}
export default Gigien