import React from "react";
import { Row, Container, Col } from "react-grid-system";
import "./DocHabibulin.scss";
import AOS from "aos";
import 'aos/dist/aos.css';
import doc from "./doc.png";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import { useMediaQuery } from 'react-responsive';
import PhotoAlbum from "react-photo-album";
import photos from "./photos";
const AutoplaySlider = withAutoplay(AwesomeSlider);

const DocHabibulin = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1225px)'
    });
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

    useEffect(() => {
        AOS.init();
    }, []);
    return(
            <>
        <Helmet>
        <title>Хабибулин Дмитрий Марсович</title>
    </Helmet>
    {isDesktopOrLaptop && <>
        <div className="DocHabibulinW1">
            <div className="doc1">
            <h1 className="animateDoc1">Хабибулин Дмитрий Марсович</h1>
            </div>
<Container>
    <Row>
        <Col xxxl={5} xxl={5} xl={5}>
        <div className="absolute inset-0 flex items-center justify-center " data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
      <div className="DocHabibulinW2"> 
       <b>Саратовский государственный медицинский университет им. В.И. Разумовского</b>
<div className="borleft"> <span>2007</span> <br />
Стоматология  <br />
<span>Высшее образование</span> <br />

<span>2008 </span><br />
Стоматология общей практики <br />
<span>Интернатура</span> <br />
<span>2009</span> <br />
Стоматология ортопедическая <br />
<span>Циклы переподготовки </span><br />
</div>
Рязанский государственный медицинский университет им. акад. И.П. Павлова <br />
<div className="borleft">
    <span>2014</span> <br />
Стоматология ортопедическая <br />
<span>Повышение квалификации</span> <br />
</div>
Медицинский университет «Реавиз» <br />
<div className="borleft">
<span>2016</span><br />
Стоматология хирургическая <br />
<span>Циклы переподготовки</span> <br />
<span>2017 </span><br />
Организация здравоохранения и общественное здоровье <br />
<span>Циклы переподготовки</span> <br />
</div>


Современная научно-технологическая академия <br />
<div className="borleft">
<span>2019</span> <br />
Стоматология ортопедическая  <br />
<span>Повышение квалификации</span> <br />
 </div>
Центр специализированного образования "Проф-Ресурс" <br />
<div className="borleft">
<span>2019</span><br />
Стоматология терапевтическая <br />
<span>Циклы переподготовки</span> <br />
<span>2020</span> <br />
Организация здравоохранения и общественное здоровье <br /> 
<span>Повышение квалификации</span> <br />
<span>2020</span> <br />
Организация здравоохранения и общественное здоровье <br />
<span>Повышение квалификации</span> <br />
</div>
 
Общий стаж работы: с 2007г - 17 лет
      </div>
        </div>
        </Col>
        <Col xxxl={7} xxl={7} xl={7}>
        <div data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
            <img src={doc}/>
            </div>
        </Col>
    </Row>
</Container>

        </div>

     <div className="DocHabibulinW1">
            <div className="doc1">
            <h1 className="animateDoc12">Сертификаты</h1>
            </div> 
</div>
        <Container>
           
          
<PhotoAlbum layout="columns" photos={photos} />;
 
       
 
  </Container>
    </>}
{/* mobile */}

    {isTabletOrMobile &&<>
        <div className="DocHabibulinW1">
            <div className="doc1">
            <h1 className="animateDoc1">Хабибулин Дмитрий Марсович</h1>
            </div>
<Container>
    <Row>
   
        <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12}>
        <div className="flex items-center justify-center " data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
            <img src={doc}/>
            </div>
        </Col>
    </Row>
    <Col xxxl={12} xxl={12} xl={12} lg={12} md={12} sm={12}>
        <div className="flex items-center justify-center " data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
      <div className="DocHabibulinW2"> 
       <b>Саратовский государственный медицинский университет им. В.И. Разумовского</b>
<div className="borleft"> <span>2007</span> <br />
Стоматология  <br />
<span>Высшее образование</span> <br />

<span>2008 </span><br />
Стоматология общей практики <br />
<span>Интернатура</span> <br />
<span>2009</span> <br />
Стоматология ортопедическая <br />
<span>Циклы переподготовки </span><br />
</div>
Рязанский государственный медицинский университет им. акад. И.П. Павлова <br />
<div className="borleft">
    <span>2014</span> <br />
Стоматология ортопедическая <br />
<span>Повышение квалификации</span> <br />
</div>
Медицинский университет «Реавиз» <br />
<div className="borleft">
<span>2016</span><br />
Стоматология хирургическая <br />
<span>Циклы переподготовки</span> <br />
<span>2017 </span><br />
Организация здравоохранения и общественное здоровье <br />
<span>Циклы переподготовки</span> <br />
</div>


Современная научно-технологическая академия <br />
<div className="borleft">
<span>2019</span> <br />
Стоматология ортопедическая  <br />
<span>Повышение квалификации</span> <br />
 </div>
Центр специализированного образования "Проф-Ресурс" <br />
<div className="borleft">
<span>2019</span><br />
Стоматология терапевтическая <br />
<span>Циклы переподготовки</span> <br />
<span>2020</span> <br />
Организация здравоохранения и общественное здоровье <br /> 
<span>Повышение квалификации</span> <br />
<span>2020</span> <br />
Организация здравоохранения и общественное здоровье <br />
<span>Повышение квалификации</span> <br />
</div>
 
Общий стаж работы: с 2007г - 17 лет
      </div>
        </div>
        </Col>
</Container>

        </div>

     <div className="DocHabibulinW1">
            <div className="doc1">
            <h1 className="animateDoc12">Сертификаты</h1>
            </div> 
</div>
        <Container>
            
          
<PhotoAlbum layout="columns" photos={photos} />
 
  </Container>
    
    </>}
</>
        
    )
}
export default DocHabibulin;