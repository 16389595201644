import React from "react";
import { Container, Row, Col } from "react-grid-system";
import "./Terap.scss";
import 'swiper/css/autoplay';
import { Helmet } from "react-helmet";
const Terap = () =>{
    return(
        <>
		<Helmet>
			<title>Терапевтические услуги</title>
		</Helmet>
        <div className="ter1">
        <h1>Наши услуги</h1>
        <span className="animateСharcterT">Терапевтические услуги</span>
     
        </div>

            <Container>
		
                <Row>
                    <Col lg={4}>
   <div className="ter2">
            <img src="/img/ter.png"  alt="imag"/>
			</div>
                    </Col>
                    <Col lg={8}>
                 <div className="ter22">
	<p> Специалисты нашей клиники занимаются терапевтическим лечение зубов любой сложности:
	 кариеса,пульпита,периодонтита,а также повторным эндодонтическим лечением 
	 (перелечиванием корневых каналов),восстановлением сильно разрушенных зубов.
	
	 Записавшись в нашу клинику, вы можете быть уверены, что каждая процедура, начиная 
	 от постановки  простой пломбы до сложного лечения , будет проведена качественно и 
	 максимально безболезненно,ведь мы подбираем анестетик в соответствии с длительностью
	  процедуры и индивидуальными особенностями каждого пациента. 
	
	  Мы используем только качественные материалы мировых производителей и систему
	   изоляции зубов от полости рта-Коффердам,что в совокупности позволяет проводить
	    лечение в стерильных условиях 
	  и гарантирует безупречный результат и комфорт пациентов.
	
	 Мы поможем вам сохранить зубы независимо от стадии развития болезни и зоны воспаления .
	 </p>

</div>
                 
				    </Col>
					</Row>
					
            </Container>
		
						<Container>
                    <table className="tbgig">
	<tbody>
	
		<tr>	
		
			<td colSpan={2} align="center">	<div className="td2" >Прейскурант цен ООО «Центр Современной Стоматологии» <br /> на терапевтические услуги</div></td>
		
		</tr>
		
        <tr>	
		
        <td colSpan={2} align="center">	<div className="td2" >Терапия</div></td>
    
    </tr>
    
		<tr>
			<td><div >Прицельная рентгенография</div></td>
			<td><div className="td2" >400</div></td>
		</tr>
		<tr>
			<td><div >Обезболивание:  инфильтрационная  анестезия</div></td>
			<td><div className="td2" >600</div></td>
		</tr>
		<tr>
			<td><div >Временное пломбирование корневого канала</div></td>
			<td><div className="td2" >500</div></td>
		</tr>
		<tr>
			<td><div >​​​​​​​Работа по распломбировке одного канала, запломбированного гуттаперчей</div></td>
			<td><div className="td2" >1 500</div></td>
		</tr>
		<tr>
			<td><div >​​​​​​​Инструментальная обработка корневого канала с использованием аппарата Morita</div></td>
			<td><div className="td2" >1 500</div></td>
		</tr>
		<tr>
			<td><div >​​​​​​Постоянное пломбирование корневых каналов одноканального зуба</div></td>
			<td><div className="td2" >3 500</div></td>
		</tr>
		<tr>
			<td><div >​​​​​Реставрационная работа из материала Estelite поверхностной кариозной полости</div></td>
			<td><div className="td2" >4 800</div></td>
		</tr>
		<tr>
			<td><div >​​​​​​​​​​​Реставрационная работа из материала Estelite средней кариозной полости</div></td>
			<td><div className="td2" >6 000</div></td>
		</tr>


        {/* <tr>
			<td><div >Обезболивание: аппликационная анестезия</div></td>
			<td><div className="td2" >400</div></td>
		</tr>
        <tr>
			<td><div >Обезболивание:  интралигаментарная анестезия</div></td>
			<td><div className="td2" >600</div></td>
		</tr>
        <tr>
			<td><div >Обезболивание:  инфильтрационная  анестезия</div></td>
			<td><div className="td2" >600</div></td>
		</tr>
        <tr>
			<td><div >Обезболивание: дополнительная анестезия</div></td>
			<td><div className="td2" >400</div></td>
		</tr>


        <tr>	
		
        <td colSpan={2} align="center">	<div className="td2" >Изолирующие прокладки</div></td>
    
    </tr>

        <tr>
			<td><div >Наложение лечебной прокладки </div></td>
			<td><div className="td2" >720</div></td>
		</tr>
        <tr>	
		
        <td colSpan={2} align="center">	<div className="td2" >Общая терапия</div></td>
    
    </tr>
        <tr>
			<td><div >Консультация врача специалиста </div></td>
			<td><div className="td2" >1 000</div></td>
		</tr>
        <tr>
			<td><div >Лазерная терапия аппаратом "Оптодан"</div></td>
			<td><div className="td2" >500</div></td>
		</tr>
        <tr>
			<td><div >Первичный прием врача специалиста </div></td>
			<td><div className="td2" >1 500</div></td>
		</tr>
        <tr>
			<td><div >Установка скайза на зуб</div></td>
			<td><div className="td2" >3 000</div></td>
		</tr>
        <tr>
			<td><div >Постановка изоляции "Рабердам"</div></td>
			<td><div className="td2" >600</div></td>
		</tr>
        <tr>
			<td><div >Изготовление окклюзионного ключа из композита</div></td>
			<td><div className="td2" >400</div></td>
		</tr>
        <tr>
			<td><div >Прицельный цифровой снимок на визиографе</div></td>
			<td><div className="td2" >400</div></td>
		</tr>
        <tr>
			<td><div >Постановка временной пломбы</div></td>
			<td><div className="td2" >600</div></td>
		</tr>
        <tr>
			<td><div >Прямое временное шинирование одного зуба</div></td>
			<td><div className="td2" >2 600</div></td>
		</tr>
        <tr>
			<td><div >Постгарантийная коррекция и полировка ранее поставленной пломбы</div></td>
			<td><div className="td2" >850</div></td>
		</tr>
        <tr>
			<td><div >Удаление ранее поставленной пломбы</div></td>
			<td><div className="td2" >600</div></td>
		</tr>
        <tr>
			<td><div >Выезд врача на дом</div></td>
			<td><div className="td2" >12 000</div></td>
		</tr>
        <tr>
			<td><div >Выезд врача по области </div></td>
			<td><div className="td2" >24 000</div></td>
		</tr>

        <tr>	
		
        <td colSpan={2} align="center">	<div className="td2" >Работа с каналами</div></td>
    
    </tr>
    <tr>
			<td><div >Закрытие перфорации МТА </div></td>
			<td><div className="td2" >2 400</div></td>
		</tr>
        <tr>
			<td><div >Извлечение инородного тела из одного канала</div></td>
			<td><div className="td2" >3 600</div></td>
		</tr>
        <tr>
			<td><div >Извлечение культевой вкладки </div></td>
			<td><div className="td2" >4 320</div></td>
		</tr>
        <tr>
			<td><div >Извлечение металлического штифта</div></td>
			<td><div className="td2" >1 800</div></td>
		</tr>
        <tr>
			<td><div >Извлечение стекловолоконного штифта </div></td>
			<td><div className="td2" >2 520</div></td>
		</tr>
        <tr>
			<td><div >Инструментальная обработка корневго канала с использованием аппарата Morita</div></td>
			<td><div className="td2" >1 500</div></td>
		</tr>
        <tr>
			<td><div >Лечение под микроскопом</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
        <tr>
			<td><div >Медикаментозная обработка корневых каналов</div></td>
			<td><div className="td2" >550</div></td>
		</tr>
        <tr>
			<td><div >Обход инородного тела в корневом канале (ByPass)</div></td>
			<td><div className="td2" >3 600</div></td>
		</tr>
        <tr>
			<td><div >Постоянное пломбирование корневых каналов  трехканального зуба</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
        <tr>
			<td><div >Постоянное пломбирование корневых каналов  четырехканального зуба</div></td>
			<td><div className="td2" >5 500</div></td>
		</tr>
        <tr>
			<td><div >Постоянное пломбирование корневых каналов двухканального зуба</div></td>
			<td><div className="td2" >4 000</div></td>
		</tr>
        <tr>
			<td><div >Постоянное пломбирование корневых каналов одноканального зуба</div></td>
			<td><div className="td2" >3 500</div></td>
		</tr>
        <tr>
			<td><div >Работа по распломбировке одного канала, запломбированного гуттаперчей</div></td>
			<td><div className="td2" >1 500</div></td>
		</tr>
        <tr>
			<td><div >Работа по распломбировке одного канала, запломбированного пастой</div></td>
			<td><div className="td2" >1 500</div></td>
		</tr>
        <tr>
			<td><div >Работа по распломбировке одного канала, запломбированного резорцин-формалиновым методом</div></td>
			<td><div className="td2" >2 400</div></td>
		</tr>
        <tr>
			<td><div >Работа по распломбировке склерозированного, облитерационного канала</div></td>
			<td><div className="td2" >3 600</div></td>
		</tr>
        <tr>
			<td><div >Ручная обработка корневых каналов: двухкорневой зуб</div></td>
			<td><div className="td2" >960</div></td>
		</tr>
        <tr>
			<td><div >Ручная обработка корневых каналов: однокорневой зуб</div></td>
			<td><div className="td2" >720</div></td>
		</tr>
        <tr>
			<td><div >Ручная обработка корневых каналов: трехкорневой зуб</div></td>
			<td><div className="td2" >1 080</div></td>
		</tr>
        <tr>
			<td><div >Ручная обработка корневых каналов: четырехкорневой зуб</div></td>
			<td><div className="td2" >1 200</div></td>
		</tr>
        <tr>
			<td><div >Сложное извлечение культевой вкладки из корневого канала</div></td>
			<td><div className="td2" >6 600</div></td>
		</tr>
        <tr>
			<td><div >Удаление внутриканального штифта/вкладки (извлечение отломка эндодантического инструмента из корневого канала под микроскопом)</div></td>
			<td><div className="td2" >6 000</div></td>
		</tr>
        <tr>
			<td><div >Временное пломбирование корневого канала</div></td>
			<td><div className="td2" >500</div></td>
		</tr>

        <tr>	
		
        <td colSpan={2} align="center">	<div className="td2" >Реставрации</div></td>
    
    </tr>



        <tr>
			<td><div >Лечение поверхностного кариеса без препарирования методикой ICON (кариес в стадии пятна)</div></td>
			<td><div className="td2" >15 500</div></td>
		</tr>
        <tr>
			<td><div >Пломбирование полости композитным материалом  (1 поверхность)</div></td>
			<td><div className="td2" >2 500</div></td>
		</tr>
        <tr>
			<td><div >Реставрационная работа из материала Estelite средней кариозной полости</div></td>
			<td><div className="td2" >6 000</div></td>
		</tr>
        <tr>
			<td><div >Восстановление коронковой части зуба  Estelite с целью дальнейшего протезирования</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
        <tr>
			<td><div >Реставрационная работа из материала Estelite глубокой кариозной полости</div></td>
			<td><div className="td2" >7 200</div></td>
		</tr>
        <tr>
			<td><div >Установка одного анкера из стекловолокна</div></td>
			<td><div className="td2" >1 000</div></td>
		</tr>
        <tr>
			<td><div >Установка одного армирующего металлического штифта  </div></td>
			<td><div className="td2" >840</div></td>
		</tr>


        <tr>	
		
        <td colSpan={2} align="center">	<div className="td2" >Эндодонтия под микроскопом</div></td>
    
    </tr>

        <tr>
			<td><div >Работа под микроскопом </div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
       */}

	</tbody>
</table>
</Container>              
               
        
        </>
    )
}
export default Terap