import React from "react";
import "./Otziv.scss"
import { Container, Row, Col } from "react-grid-system";
import { Helmet } from "react-helmet";
const Otziv = () =>{
  return(
        <>
        <Helmet>
            <title>Отзывы</title>
        </Helmet>
<Container>
    <div className="otz">
<Row>
    <Col lg={4}>
<div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="500">
        <div className="otz1">
            <div className="otz11">
            <p><img src="/img/otz7.png" alt="" /></p>
            </div>
        <div className="otz12">
        <p>Хочу сказать большое спасибо доктору Дмитрию Марсовичу,
            делал 2 зуба, все очень даже хорошо, аккуратно работает,
             ничего не чувствуется Спасибо большое, рекомендую посетить
              данного доктора и клинику </p>
        </div>
        </div>
</div>
</Col>




<Col lg={4}>
<div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="500">
        <div className="otz1">
            <div className="otz11">
            <p><img src="/img/otz7.png" alt="" /></p>
            </div>
        <div className="otz12">
        <p>Хорошая клиника, с грамотно подобранным персоналом. 
            Работают на проверенных и качественных материалах.
             Обращалась несколько раз по совету знакомой. Лечила зубы
              у Дмитрия Марсовича, провели удаление у Вачика Артушевича,
               также ребёнку поставили брекеты у Каролины Игоревны,
                и у неё уже красивая и ровная улыбка 🙂. </p>
        </div>
        </div>

</div>
</Col>



<Col lg={4}>
<div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="500">
        <div className="otz1">
            <div className="otz11">
            <p><img src="/img/otz7.png" alt="" /></p>
            </div>
        <div className="otz12">
        <p>Здравствуйте, лечила зубки у терапевта - Хабибулина Дмитрия Марсовича, врач очень внимательный, грамотный ,все объясняет на доступном пациенту языке. Работает очень аккуратно, не было никакого даже малейшего дискомфорта как во время, 
            так и после лечения. Спасибо большое, всем советую данную клинику </p>
        </div>
        </div>
        </div>
        </Col>
        </Row>
        <Row>
    <Col lg={4}>
<div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="500">
        <div className="otz1">
            <div className="otz11">
            <p><img src="/img/otz7.png" alt="" /></p>
            </div>
        <div className="otz12">
        <p>Делала профгигиену. Врач Оболенкина Ирина Алексеевна 
            очень внимательная, коментировала каждое своё действие. 
            Всё подробно рассказала как следить за здоровьем зубов.
             Спасибо. Благодарю. </p>
        </div>
        </div>
</div>
</Col>




<Col lg={4}>
<div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="500">
        <div className="otz1">
            <div className="otz11">
            <p><img src="/img/otz7.png" alt="" /></p>
            </div>
        <div className="otz12">
        <p>Хочется сказать огромное спасибо стоматологу-хирургу
             данной клиники Коняхиной Виктории Евгеньевне. Большой
             профессионал и мастер своего дела! 
            Однозначно буду ее рекомендовать с положительной стороны ! </p>
        </div>
        </div>

</div>
</Col>



<Col lg={4}>
<div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="500">
        <div className="otz1">
            <div className="otz11">
            <p><img src="/img/otz7.png" alt="" /></p>
            </div>
        <div className="otz12">
        <p>Лечим зубы всей семьей у Хабибулина Дмитрия Марсовича много лет.
             Отличный врач, добрый, отзывчивый, внимательный, профессионал своего дела! 👍👍👍 
             И с острой болью обращались и плановое лечение делали и профилактику. Все четко, 
             понятно, без лишних слов. А главное он не будет удалять зуб без лишней надобности, если можно его спасти и ходить со своими зубами. Очень рекомендуем! 
          </p>
        </div>
        </div>
        </div>
        </Col>
        </Row>

        </div>
        </Container>
        </>
    )
}
export default Otziv

