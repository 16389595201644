import React from "react";
import { Container, Row, Col } from "react-grid-system";
import "./Implant.scss";
import { Helmet } from "react-helmet";
const Implant = () => {
    return(
        <>
		<Helmet>
			<title>Услуги имплантации</title>
		</Helmet>
                <div className="imp1">
        <h1>Наши услуги</h1>
        <span className="animateСharcterImp">Услуги имплантации</span>
     
        </div>

            <Container>
		
                <Row>
                    <Col lg={5}>
   <div className="imp2">
            <img src="/img/Uslug/impl.jpg"  alt="imag"/>
			</div>
                    </Col>
                    <Col lg={7}>
						<div className="impl22">
							<p>
						Имплантация зубов в нашей клинике   — современная и недорогая операция восстановления 
						зубного ряда, когда на место корня устанавливается винтообразный титановый стержень:
						 цена услуги установки имплантов под ключ демократична, поэтому стоимость доступна для 
						 каждого пациента. За счет своей особой поверхности он интегрируется в костную ткань, 
						 врастает в нее, становится частью организма. Затем на имплантат закрепляют опору 
						 (абатмент) и коронку. Метод сохраняет нетронутыми соседние зубы, которые страдают
						  при мостовидном протезировании. И открывает больше возможностей для решения серьезных 
						  стоматологических проблем, с которыми традиционные «мосты» и съемные протезы не справляются.
						Все виды дентальной имплантации, адекватные цены, опытные хирурги,
						 собственная цифровая зуботехническая лаборатория ждут вас в
						  «Центр Современной Стоматологии».
						  </p>
</div>
                    </Col>
					</Row>
					
            </Container>
		<br />
						<Container>
                    <table className="tbgig">
	<tbody>
		<tr>
        <td colSpan={2} align="center">	<div className="td2" >Имплантация</div></td>
    </tr>



        <tr>
			<td><div >Консультация специалиста</div></td>
			<td><div className="td2" >1 000</div></td>
		</tr> 
		 <tr>
			<td><div >Операция дентальной имплантации постановка одного импланта Snucone</div></td>
			<td><div className="td2" >30 000</div></td>
		</tr> 
		<tr>
			<td><div >Операция дентальной имплантации постановка одного импланта INNO</div></td>
			<td><div className="td2" >35 000</div></td>
		</tr>
			<tr>
			<td><div >Операция дентальной имплантации постановка одного импланта Any Ridge</div></td>
			<td><div className="td2" >40 000</div></td>
		</tr>
		<tr>
			<td><div >​​​​​​​Операция дентальной имплантации постановка одного импланта Nobel Biocare</div></td>
			<td><div className="td2" >55 000</div></td>
		</tr>
		<tr>
			<td><div >​​​​​​​​​​​​​Имплантация и временное протезирование Nobel Biocare по концепции ALL-ON-4</div></td>
			<td><div className="td2" >550 000</div></td>
		</tr>
        {/* <tr>
			<td><div >Удаление интегрированного  импланта</div></td>
			<td><div className="td2" >10 000</div></td>
		</tr>
        <tr>
			<td><div >Использование абатмента Multi-unit Xeal Conical</div></td>
			<td><div className="td2" >21 000</div></td>
		</tr>
        <tr>
			<td><div >Использование заживляющего колпачка Multi-unit</div></td>
			<td><div className="td2" >2 000</div></td>
		</tr>
        <tr>
			<td><div >Заполнение костного дефекта ксеногенным костным материалом 0,5 гр</div></td>
			<td><div className="td2" >15 000</div></td>
		</tr>
        <tr>
			<td><div >Заполнение костного дефекта ксеногенным костным материалом 1 гр</div></td>
			<td><div className="td2" >23 000</div></td>
		</tr>
        <tr>
			<td><div >Костная пластика с использованием  i-Gen </div></td>
			<td><div className="td2" >32 300</div></td>
		</tr>
        <tr>
			<td><div >Изготовление индивидуального формирователя десны</div></td>
			<td><div className="td2" >7 000</div></td>
		</tr>

        <tr>
			<td><div >Имплантация Nobel Biocare по концепции ALL-ON-4</div></td>
			<td><div className="td2" >350 000</div></td>
		</tr>
        <tr>
			<td><div >Имплантация и временное протезирование Nobel Biocare по концепции ALL-ON-4</div></td>
			<td><div className="td2" >500 000</div></td>
		</tr>
        <tr>
			<td><div >Имплантация и временное протезирование Nobel Biocare по концепции ALL-ON-6</div></td>
			<td><div className="td2" >550 000</div></td>
		</tr>
        <tr>
			<td><div >Имплантация и постоянное протезирование Nobel Biocare по концепции Trefoil</div></td>
			<td><div className="td2" >500 000</div></td>
		</tr>
        <tr>
			<td><div >Операция дентальной имплантации постановка одного импланта Any Ridge</div></td>
			<td><div className="td2" >38 000</div></td>
		</tr>
        <tr>
			<td><div >Операция дентальной имплантации постановка одного импланта INNO</div></td>
			<td><div className="td2" >35 000</div></td>
		</tr>
        <tr>
			<td><div >Операция дентальной имплантации постановка одного импланта Nobel Biocare</div></td>
			<td><div className="td2" >53 000</div></td>
		</tr>
        <tr>
			<td><div >Операция дентальной имплантации постановка одного импланта Snucone</div></td>
			<td><div className="td2" >28 500</div></td>
		</tr>
        <tr>
			<td><div >Применение «Ролл» техники в области имплантата или формирователя десны</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
        <tr>
			<td><div >Установка десневого формирователя INNO</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
        <tr>
			<td><div >Установка десневого формирователя Nobel</div></td>
			<td><div className="td2" >5 500</div></td>
		</tr>
        <tr>
			<td><div >Установка десневого формирователя Straumann</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
        <tr>
			<td><div >Установка десневого формирователя для Any Ridge</div></td>
			<td><div className="td2" >5 000</div></td>
		</tr>
        <tr>
			<td><div >Установка десневого формирователя для Snucone</div></td>
			<td><div className="td2" >4 000</div></td>
		</tr>
        <tr>
			<td><div >Увеличение зоны прикрепленной десны (стрип- пластика)</div></td>
			<td><div className="td2" >16 000</div></td>
		</tr>
        <tr>
			<td><div >Увеличение объема десны в области имплантанта</div></td>
			<td><div className="td2" >6 500</div></td>
		</tr>


        <tr>	
		
        <td colSpan={2} align="center">	<div className="td2" >Имплантация и протезирование</div></td>
    </tr>




        <tr>
			<td><div >Изготовление и фиксация временного протеза с опорой на 6 имплантатов с уровня мультиюнита из РММА</div></td>
			<td><div className="td2" >77 000</div></td>
		</tr>
        <tr>
			<td><div >Ремонт постоянного протеза с опорой на 6 имплантатов Nobel Biocare</div></td>
			<td><div className="td2" >20 000</div></td>
		</tr>
        <tr>
			<td><div >Установка несъемного временного мостовидного протеза </div></td>
			<td><div className="td2" >100 000</div></td>
		</tr>
        <tr>
			<td><div >Установка несъемного временного мостовидного протеза с опрой на 6 имплантатах Snucone</div></td>
			<td><div className="td2" >150 000</div></td>
		</tr>
        <tr>
			<td><div >Установка несъемного временного протеза на одну челюсть с опорой на  4 имплантата Nobel Biocare</div></td>
			<td><div className="td2" >250 000</div></td>
		</tr>
        <tr>
			<td><div >Установка постоянного протеза с опорой на 4 имплантата Nobel Biocare</div></td>
			<td><div className="td2" >220 000</div></td>
		</tr>
        <tr>
			<td><div >Установка постоянного протеза с опорой на 6 имплантатах Nobel Biocare</div></td>
			<td><div className="td2" >170 000</div></td>
		</tr>
        <tr>
			<td><div >Установка постоянного протеза с опорой на 6 имплантатах Snucon</div></td>
			<td><div className="td2" >120 000</div></td>
		</tr> */}




        
		

	</tbody>
</table>
</Container>              
               
        
        </>
    )
}
export default Implant