import React from "react";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "react-grid-system";
import "./Konkt.scss"
const Konkt = () => {
    return(
        <>
        <Helmet>
           <title>Контакты</title>
            </Helmet>
        <Container>
<Row className="konkt">
    <Col lg={12} className="konkt1"> НАШИ КОНТАКТЫ</Col>
    <Col lg={7} className="konkt2">
    <img src="/img/konkt.png"/>
    </Col>
    <Col lg={5} className="konkt3">
    <p>392036 г.Тамбов, ул.Интернациональная, 92, пом.56 </p>
<p className="konkt4">+7 (4752) 55-95-32</p> 
<p className="konkt4">+7 (920) 471-18-88</p> 
    </Col>
    <Col lg={12}>
        
       *Лицензия номер Л041-01196-68/00359963 выдана Министерство здравоохранения Тамбовской области от 08.10.2018г.</Col>
</Row>
<Row>
                         <Col Align="center" md={12} lg={12} xl={12}>
                             <iframe
                                 src="https://yandex.ru/map-widget/v1/?um=constructor%3A02de185c11bea7d571482104f9047e05cec610f4fd23c88cd48ddbeca0349539&amp;source=constructor"
                                 width="100%" height="520" frameBorder="0"></iframe>       </Col>
                     </Row>

        </Container>

        </>
    )
}
export default Konkt;