
const photos = [
    {
      src: "/img/DocGukasyan/cert/1.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/35.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/2.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/36.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/3.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/37.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/4.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/38.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/5.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/39.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/6.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/40.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/7.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/41.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/8.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/42.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/9.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/43.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/10.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/44.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/11.png",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/45.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/12.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/46.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/13.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/47.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/14.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/48.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/15.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/49.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/16.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/50.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/17.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/51.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/18.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/52.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/19.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/54.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/20.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/55.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/21.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/56.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/22.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/57.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/23.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/58.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/24.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/59.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/25.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/60.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/26.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/61.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/27.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/62.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/28.jpg",
      width: 4,
      height: 3
    },
    {
      src: "/img/DocGukasyan/cert/63.jpg",
      width: 3,
      height: 4
    },
    {
      src: "/img/DocGukasyan/cert/29.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocGukasyan/cert/30.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocGukasyan/cert/31.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocGukasyan/cert/32.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocGukasyan/cert/33.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocGukasyan/cert/34.jpg",
      width: 4,
      height: 3
    },
  ];
  export default photos;
  