
const photos = [
    {
      src: "/img/DocKarolina/cert/1.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/2.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/3.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/4.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/5.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/6.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/7.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/8.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/9.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/10.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/11.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/12.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/13.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/14.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/15.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/16.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/17.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/18.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/19.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/20.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/21.jpg",
      width: 3,
      height: 4
    },{
      src: "/img/DocKarolina/cert/22.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/23.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/24.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/25.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/26.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/27.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/28.jpg",
      width: 4,
      height: 3
    },{
      src: "/img/DocKarolina/cert/29.jpg",
      width: 4,
      height: 3
    },  ];
  export default photos;
  