import React from "react";
import { Routes, Route} from "react-router-dom";
import Err from "../Err/Err";
import Content from "../Content/Content";
import Uslugi from "../Uslugi/Uslugi";
import Nas from "../Nas/Nas";
import Otziv from "../Otziv/Otziv";
import Ortoped from "../LinContent/Ortoped/Ortoped";
import Ordont from "../LinContent/Ordont/Ordont";
import Terap from "../LinContent/Terap/Terap";
import Hirurg from "../LinContent/Hirurg/Hirurg";
import Gigien from "../LinContent/Gigien/Gigien";
import Implant from "../LinContent/Implant/Implant";
import Konkt from "../Konkt/Konkt";
import DocHabibulin from "../Nas/DocHabibulin/DocHabibulin";
import DocGukasyan from "../Nas/DocGukasyan/DocGukasyan";
import DocObolenkina from "../Nas/DocObolenkina/DocObolenkina";
import DocKonyahina from "../Nas/DocKonyahina/DocKonyahina";
import DocRegina from "../Nas/DocRegina/DocRegina";
import DocDrushkina from "../Nas/DocDrushkina/DocDrushkina";
import Laboratory from "../LinContent/Laboratory/Laboratory";
import DocKarolina from "../Nas/DocKarolina/DocKarolina";

const Rout = () => {
    return(
        <>
        

        <Routes>
            <Route index element={<Content/>} />
            <Route path="*" element={<Err/>} status={404}/>       
            <Route path="uslugi" element={<Uslugi/>}/>
            <Route path="nas" element={<Nas/>}/>
            <Route path="otziv" element={<Otziv/>}/>
            <Route path="ortoped" element={<Ortoped/>}/> 
            <Route path="ordont" element={<Ordont/>}/>
            <Route path="terap" element={<Terap/>}/>
            <Route path="hirurg" element={<Hirurg/>}/>
            <Route path="gigien" element={<Gigien/>}/>
            <Route path="impl" element={<Implant/>}/>
            <Route path="konkt" element={<Konkt/>}/>
            <Route path="laboratory" element={<Laboratory/>}/>
          <Route path="DocHabibulin" element={<DocHabibulin/>}/>
          <Route path="DocGukasyan" element={<DocGukasyan/>}/>
          <Route path="DocObolenkina" element={<DocObolenkina/>}/>
          <Route path="DocKonyahina" element={<DocKonyahina/>}/>
          <Route path="DocRegina" element={<DocRegina/>}/>
          <Route path="DocDrushkina" element={<DocDrushkina/>}/>
          <Route path="DocKarolina" element={<DocKarolina/>}/>
        </Routes>

     </>
    )


}


export default Rout