import React, { useRef, useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import "./Laboratory.scss";
import 'swiper/css/autoplay';
import { Helmet } from "react-helmet";
import { Parallax, ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";
import video from "./Lab.MP4";
import PhotoAlbum from "react-photo-album";
import photosLab from "./photosLab";

const Laboratory = () => {
  // const videoRef = useRef(null);
  // const [videoWidth, setVideoWidth] = useState("40%");
  // const [prevScrollPos, setPrevScrollPos] = useState(0);
  // const [videoElementRect, setVideoElementRect] = useState(null);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const videoElement = videoRef.current;
  //     if (!videoElement) return;

  //     const rect = videoElement.getBoundingClientRect();
  //     setVideoElementRect(rect);

  //     const currentScrollPos = window.pageYOffset;
  //     const isScrollingDown = currentScrollPos > prevScrollPos;

  //     if (isScrollingDown && rect.top <= window.innerHeight) {
  //       setVideoWidth("100%");
  //     } else {
  //       setVideoWidth("40%");
  //     }

  //     setPrevScrollPos(currentScrollPos);
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [prevScrollPos]);

  // useEffect(() => {
  //   const video = videoRef.current;
  //   video.addEventListener('ended', () => {
  //     video.currentTime = 0;
  //     video.play();
  //   });
  // }, []);

  return (
    <>
      <Helmet>
        <title>Лаборатория</title>
      </Helmet>
      <div className="gig1">
        <h1>Наши услуги</h1>
        <span className="animateСharcterG">ЦИФРОВАЯ ЗУБОТЕХНИЧЕСКАЯ ЛАБОРАТОРИЯ</span>
     
        </div>
<Container>
        <div className="lab22">
        <p>
        Для Вас мы изготавливаем: <br />

•провизорные коронки на имплант для формирования контура десны <br />

•постоянные коронки диоксид циркония с опорой на имплант с учетом анатомии и оттенков соседних зубов <br />

•постоянные коронки диоксид циркония с прозрачным режущим краем с опорой на собственный зуб <br />

•керамические вкладки inlay/onlay  <br />

•восковое моделирование коронковой части зубов ( wax-up )
</p>
</div>
</Container>
      <ParallaxProvider>
        <Parallax speed={10}>
          <Container>
            <PhotoAlbum spacing={20} padding={10} columns={2} layout="columns" photos={photosLab} />
          </Container>
        </Parallax>
      </ParallaxProvider>

      <video
        // ref={videoRef}
        src={video}
        autoPlay={true}
        controls={false}
        loop={true}
        width={"100%"}
        // style={{
        //   width: videoWidth,
        //   transition: "width 2.5s ease",
        //   position: "relative",
        //   left: "50%",
        //   transform: "translateX(-50%)",
        // }}
      />
    </>
  );
};

export default Laboratory;